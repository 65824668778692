import React, { useState, useEffect } from "react";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TreeItem2, TreeItem2Content, TreeItem2Label } from "@mui/x-tree-view";
import { useTreeViewApiRef } from "@mui/x-tree-view";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useUiStore from "../../../stores/useUiStore";

import useTableOfContent from "../../hooks/useTableOfContent";

const TableDesMatieres = React.forwardRef((props, ref) => {
  const {
    style,
    setId,
    // setSelectedItems,
    // selectedItems,
    // handleSelectedItemsChange,
    contexte,
  } = props;
  const toc = useTableOfContent();

  const paramsTexte = useUiStore((state) => state.paramsTexte);

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const apiRef = useTreeViewApiRef();
  const [selectedItems, setSelectedItems] = useState(null);

  const handleSelectedItemsChange = (event, itemId) => {
    if (itemId == null) {
      setSelectedItems(null);
    } else {
      setSelectedItems(apiRef.current.getItem(itemId));

      contexte === "texte"
        ? navigate(`/texte/${itemId}`)
        : useUiStore.setState({
            paramsTexte: {
              ...paramsTexte,
              currentTexte: itemId,
              cardTexteOpen: true,
            },
          });
    }
  };

  const CustomTreeItem = React.forwardRef((props, ref) => {
    const { label, itemId, id, ...othersProps } = props;

    return (
      <TreeItem2
        ref={ref}
        {...othersProps}
        label={<div dangerouslySetInnerHTML={{ __html: label }} />}
        itemId={itemId}
        id={id}
      />
    );
  });

  function getItemId(item) {
    return item.subtype;
  }

  return toc ? (
    <RichTreeView
      apiRef={apiRef}
      items={toc}
      slots={{ item: CustomTreeItem }}
      sx={style}
      getItemId={getItemId}
      // selectedItems={selectedItem?.id ?? null}
      onSelectedItemsChange={handleSelectedItemsChange}
    />
  ) : null;
});

export default TableDesMatieres;
