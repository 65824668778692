import React from "react";
import { useTheme } from "@mui/material/styles";

const useColors = () => {
  const theme = useTheme();
  const { palette } = theme;
  const colors = {
    base: {
      fill: {
        hovered: "orange",
        selected: "peru",
        idle: palette.text.disabled,
      },
      stroke: {
        hovered: "orange",
        selected: "peru",
        idle: palette.text.secondary,
      },
    },
    architrave: {
      fill: { hovered: palette.action.disabled, idle: palette.action.selected },
      stroke: { idle: palette.action.disabled },
    },
    crepis: {
      fill: {
        hovered: palette.action.disabled,
        idle: palette.action.hover,
      },
      stroke: { idle: palette.action.disabled },
    },
  };

  return colors;
};

export default useColors;
