import React, { useEffect, useState, useRef, useMemo, Suspense } from "react";

import useModelStore from "../../../stores/useModelStore";
import { useParams, Link } from "react-router-dom";

import CanvasFragments from "./canvasFragments";
import Statistiques from "./statistiques";

import SettingsCard from "./ui/settingsCard";
import Fabs from "./ui/fabs";
import FakeFabs from "./ui/fakeFabs";
import BottomDrawer from "./ui/bottomDrawer";
import PaginationFragments from "./ui/paginationFragments";
import useUiStore from "../../../stores/useUiStore";

import { multiFilter, getFragmentsAnnotations } from "../../../outils";

const Fragments = () => {
  const { fragments, filter, filteredFragments, setFilteredFragments } =
    useModelStore((state) => {
      return {
        fragments: state.fragments,
        filter: state.filter,
        filteredFragments: state.filteredFragments,
        setFilteredFragments: state.setFilteredFragments,
      };
    });

  const { paramsPagination, paramsFragments, paramsTexte } = useUiStore(
    (state) => {
      return {
        paramsPagination: state.paramsPagination,
        paramsFragments: state.paramsFragments,
        paramsTexte: state.paramsTexte,
      };
    }
  );

  const [openSettings, setOpenSettings] = useState(false);
  const handleSettings = (value) => (e) => {
    setOpenSettings(value);
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleDrawer = (value) => (e) => {
    setOpenDrawer(value);
  };

  const myFragmentsRef = useRef(fragments);

  useEffect(() => {
    setFilteredFragments(multiFilter(myFragmentsRef.current, filter));
  }, [JSON.stringify(filter)]);

  return (
    <>
      {/* <div className="Background-Image"></div> */}

      <div className="titre">Corpus général des fragments numérisés</div>
      <div className="sur-titre">Décor sculpté de la tholos</div>

      {paramsFragments?.stats?.value ? (
        <Statistiques fragments={fragments}></Statistiques>
      ) : null}

      {/* <Suspense fallback={null}> */}
      <div className="webgl">
        <CanvasFragments name="Fragments" fragments={filteredFragments} />
      </div>
      {/* </Suspense> */}

      <div
        id="reglages-fragments"
        sx={{ visibility: openSettings ? "visible" : "hidden" }}
      >
        <SettingsCard open={openSettings} handleSettings={handleSettings} />
      </div>
      <div id="recherche-fragments">
        <BottomDrawer handleDrawer={handleDrawer} open={openDrawer} />
      </div>

      <footer>
        <div className="footer">
          <span className="alignLeft">
            <Fabs
              handleSettings={handleSettings}
              handleDrawer={handleDrawer}
              fragmentsNumber={filteredFragments.length}
            />
          </span>
          <span className="alignRight">
            <FakeFabs number={6} />
          </span>
        </div>
        <span style={{ float: "center" }}>
          {/* <Suspense fallback={null}> */}
          {paramsPagination?.grid > 0 ? <PaginationFragments /> : null}
          {/* </Suspense> */}
        </span>
      </footer>
    </>
  );
};

export default Fragments;
