import React, { useState, useEffect } from "react";
import { XMLParser } from "fast-xml-parser";
import xmlParserOptions from "../chapitres/notices/common/xmlParserOptions";

const useFastXmlParser = (data, parserOptions) => {
  const [json, setJson] = useState(null);

  const parser = new XMLParser(parserOptions || xmlParserOptions);

  function parseData(data) {
    return parser.parse(data);
  }

  useEffect(() => {
    if (data) {
      setJson(parseData(data));
    }
  }, [data]);

  return json;
};

export default useFastXmlParser;
