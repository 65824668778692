import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRoute, useLocation, Route } from "wouter";
import { Typography, Box } from "@mui/material";

import { ListOccurences } from "../common/ui";

import {
  useQueries,
  useQueriesAPI,
  useKeys,
  useGetOccurences,
} from "../../hooks";

import useModelStore from "../../../stores/useModelStore";
import useThesoStore from "../../../stores/useThesoStore";

import { getAnnotedFragments } from "../../../outils";

import CanvasSelection from "../selections/canvasSelection";

const Code = (props) => {
  const { code } = useParams();
  const { couche } = props;
  const navigate = useNavigate();
  const [loc, setLocation] = useLocation();
  const [, params] = useRoute("/:fragments/:couche/:code");

  const coucheID = {
    materialite: "ff",
    iconographie: "00",
  };

  const notation = `#${code}${coucheID[couche]}`;

  const setCurrentSelection = useModelStore(
    (state) => state.setCurrentSelection
  );

  const currentTheso = useThesoStore((state) => state.thesaurii[couche]).find(
    (t) => t?.notation?.[`#text`] === `${notation}`
  );

  // currentTheso &&
  //   console.log(
  //     currentTheso
  //       // .filter((t) => t.notation)
  //       .filter((t) => t?.notation?.[`#text`] === `${notation}`)
  //   );

  // currentTheso && console.log(currentTheso);

  useKeys("ArrowUp", () => navigate(`/fragments/${params.couche}`));

  /**
   *  useQueries
   */

  const {
    data: dataFragments,
    isSuccess: isSuccessFragments,
    isLoading: isLoadingFragments,
  } = useQueries(`/json/annotationsParFragment.json`);

  const {
    data: dataDefinitions,
    isSuccess: isSuccessDefinitions,
    isLoading: isLoadingDefinitions,
  } = useQueriesAPI(`palettes/${couche}`);

  const {
    data: fragments,
    isSuccess: isSuccess,
    isLoading: isLoading,
  } = useQueriesAPI(`fragments`);

  /**
   *
   */

  const annotations = isSuccessFragments && dataFragments[couche];
  const definitions = isSuccessDefinitions && dataDefinitions;

  const occurences = useGetOccurences({
    type: "opentheso",
    id: currentTheso?.identifier?.[`#text`],
  });

  const definition =
    isSuccessDefinitions &&
    dataDefinitions &&
    definitions?.find((d) => d.hex === `#${code}`);

  const res = annotations && getAnnotedFragments(notation, annotations);

  useEffect(() => {
    if (res && res.length > 0) {
      setCurrentSelection(res);
    } else {
      navigate("/404");
    }
  }, [res]);

  // console.log(definition);

  return (
    isSuccess &&
    isSuccessDefinitions &&
    isSuccessFragments && (
      <>
        {definition ? (
          <>
            {/* <div className="sur-titre">{definition.descripteur}</div> */}
            <div className="titre">{definition.descripteur}</div>
            {currentTheso?.definition && (
              <div className="definition">
                <Typography>{currentTheso?.definition?.[`#text`]}</Typography>
              </div>
            )}
            {/* <div className="entete">{definition.descripteur}</div> */}
          </>
        ) : null}
        {occurences && occurences.length > 0 && (
          <ListOccurences occurences={occurences} />
        )}

        <CanvasSelection />
      </>
    )
  );
};

export default Code;
