import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

import { multiFilter } from "../outils";
import { teal } from "@mui/material/colors";
// const fs = require("fs");

const baseUrl = import.meta.env.VITE_SERVER_API;

// const baseUrl = "https://api.archeo3d.org/tholos";

const useModelStore = create((set) => ({
  currentModel: {
    file: "4312-2",
    label: "4312,2",
  },
  setCurrentModel: (currentModel) =>
    set((state) => ({ ...state, currentModel })),
  modeles: [],
  fragments: [],
  currentFragment: {},
  currentSelection: [],
  setCurrentSelection: (currentSelection) =>
    set((state) => ({ ...state, currentSelection })),
  setCurrentFragment: (currentFragment) =>
    set((state) => ({ ...state, currentFragment })),
  currentLayer: { label: "base", value: "base", code: "0f" },
  setCurrentLayer: (currentLayer) =>
    set((state) => ({ ...state, currentLayer })),
  layers: {},
  setLayers: (layers) => set((state) => ({ ...state, layers })),
  canvasSize: 512,
  setCanvasSize: (canvasSize) => set((state) => ({ ...state, canvasSize })),
  filteredFragments: 0,
  setFilteredFragments: (filteredFragments) =>
    set((state) => ({ ...state, filteredFragments })),
  filter: { gltf: true, tei: false },
  setFilter: (filter) => set((state) => ({ ...state, filter })),
  currentInput: {},
  setCurrentInput: (currentInput) =>
    set((state) => ({ ...state, currentInput })),
}));

// fetch("/json/modeles.json")
//   .then((resp) => resp.json())
//   .then((modeles) =>
//     useModelStore.setState((state) => ({
//       ...state,
//       modeles,
//     }))
//   );

let random;

fetch(`${baseUrl}/fragments`)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error("pb");
  })
  .then(
    (fragments) => (
      (random = Math.floor(Math.random() * (fragments.length - 0))),
      useModelStore.setState((state) => ({
        ...state,
        fragments,
      })),
      useModelStore.setState((state) => ({
        ...state,
        currentFragment: fragments
          .filter((item) => item.gltf)
          // .filter((item) => item.tei)
          // .find((item, index) => index === random),// plante = restreindre le random aux seules pieces en gltf
          .find((item, index) => item.id === 93),
      })),
      useModelStore.setState((state) => ({
        ...state,
        filteredFragments: multiFilter([...fragments], state.filter),
      }))
    )
  )

  .catch((error) => {
    console.log(error);
  });

if (import.meta.env.VITE_NODE_ENV === "development") {
  mountStoreDevtool("ModelStore", useModelStore);
}

export default useModelStore;
