import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { fontGrid } from "@mui/material/styles/cssUtils";

const useUiStore = create((set) => ({
  config: {},
  random: false,
  updateRandom: () => {
    set((state) => ({ random: !state.random }));
  },
  paramsTholos: {
    updatedRandom: { value: false, label: "Placement aléatoire", index: 0 },
    decor: {
      value: true,
      label: "Décor sculpté",
      index: 0,
      type: "switch",
    },
    reconstitution: {
      value: true,
      label: "Edifice",
      index: 1,
      type: "switch",
    },
    anastyloseSite: {
      value: true,
      label: "Etat actuel",
      index: 2,
      type: "switch",
    },
    entablementMusee: {
      value: false,
      label: "Anastylose musée",
      index: 3,
      type: "aucun",
    },
    splat: {
      value: false,
      label: "Environnement",
      index: 4,
      type: "switch",
      admin: true,
    },
  },
  paramsFragments: {
    scaled: {
      value: false,
      label: "mettre à l'échelle",
      index: 0,
      modeSelection: true,
    },
    animated: {
      value: true,
      label: "animer les fragments",
      index: 1,
      modeSelection: true,
    },
    lit: {
      value: true,
      label: "éclairage dynamique",
      index: 2,
      modeSelection: true,
    },
    numero: {
      value: true,
      label: "numéro d'inventaire",
      index: 3,
      modeSelection: true,
    },
    legend: {
      value: false,
      label: "légende au survol",
      index: 4,
      modeSelection: false,
    },
    stats: {
      value: false,
      label: "statistiques (prov)",
      index: 5,
      modeAdmin: true,
      modeSelection: false,
    },
  },
  paramsFragment: {
    diffuse: { value: 1.0, label: "texture", index: 0, type: "slider" },
    normale: { value: 0.5, label: "micro-relief", index: 1, type: "slider" },
    lit: {
      value: false,
      label: "éclairage dynamique",
      index: 2,
      type: "switch",
    },
    reframe: { value: false },
    annotationsOn: { value: true },
  },
  setParamsTholos: (paramsTholos) =>
    set((state) => ({ ...state, paramsTholos })),
  setParamsFragments: (paramsFragments) =>
    set((state) => ({ ...state, paramsFragments })),
  setParamsFragment: (paramsFragment) =>
    set((state) => ({ ...state, paramsFragment })),

  paramsPagination: { grid: 1, page: 1 },
  setParamsPagination: (paramsPagination) =>
    set((state) => ({ ...state, paramsPagination })),
  cameraFragments: { fov: 5, near: 0.01, far: 60, position: [0, 0, 40] },
  setCameraFragments: (cameraFragments) =>
    set((state) => ({ ...state, cameraFragments })),
  paramsTexte: {
    drawerTexteOpen: false,
    drawerTocOpen: false,
    cardTexteOpen: false,
    modalTexteOpen: false,
    currentTexte: "intro",
    fontSize: 15,
    mode: "dark",
  },
}));

fetch(`/json/config.json`)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error({ name: "Error", message: "pas de retour" });
  })

  .then((config) =>
    useUiStore.setState((state) => ({
      ...state,
      config,
    }))
  )
  .catch((error) => {
    console.log(error);
  });

if (import.meta.env.VITE_NODE_ENV === "development") {
  mountStoreDevtool("UiStore", useUiStore);
}

export default useUiStore;
