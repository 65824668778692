import React, { useState, useEffect } from "react";
import * as jp from "jsonpath";

import { useNavigate, useMatch } from "react-router-dom";

import { useKeys, useSections } from "../../hooks";

import useModelStore from "../../../stores/useModelStore";

import { parseExternalUrl } from "../../../outils";

import Carrousel from "../../ui/carrousel/carrousel";

import CanvasSelection from "../selections/canvasSelection";

const Sections = () => {
  const { params } = useMatch("/fragments/:section");

  const [activeIndex, setActiveIndex] = useState();
  const [fragments, setFragments] = useState();

  const setCurrentSelection = useModelStore(
    (state) => state.setCurrentSelection
  );

  const navigate = useNavigate();

  const sections = useSections();

  let sectionsWithFragments = sections?.filter((section) =>
    section.p?.some((p) => p.ref)
  );
  let currentSection = sections?.find((s) => s.subtype === params.section);

  let length = sectionsWithFragments?.length;

  /**
   *
   *
   */

  const handlePageChange = (value) => {
    let previousIndex = activeIndex + value;
    if (previousIndex >= 0 && previousIndex <= length - 1) {
      setActiveIndex((currentIndex) => currentIndex + value);
    }
  };

  useKeys("ArrowRight", () => setTimeout(handlePageChange(1), 100));
  useKeys("ArrowLeft", () => setTimeout(handlePageChange(-1), 100));
  useKeys("ArrowUp", () => navigate("/fragments"));

  /**/

  useEffect(() => {
    if (!sectionsWithFragments?.length) return;

    let index = sectionsWithFragments
      ?.map((s) => s.subtype)
      .indexOf(params.section);
    setActiveIndex(index);

    let fragmentsQuery = jp.query(
      sections,
      `$..[?(@.subtype=="${params.section}")]..ref[*].target`
    );
    let fragmentsRes = [...new Set(fragmentsQuery)]
      ?.map((f) => {
        if (parseExternalUrl(f).domain === "tholos")
          return parseExternalUrl(f).fragment;
      })
      .filter((i) => i);

    setFragments(fragmentsRes);
    setCurrentSelection(fragmentsRes.map((f) => f.replace(",", "-")));
  }, [sectionsWithFragments?.length, params.section]);

  /**/

  useEffect(() => {
    if (activeIndex !== undefined && sectionsWithFragments.length) {
      navigate(`/fragments/${sectionsWithFragments[activeIndex].subtype}`);
    }
  }, [activeIndex, params.section]);

  return (
    <>
      <div className="sur-titre">Fragments cités dans le texte</div>
      <div className="titre">{currentSection?.head}</div>

      <div className="webgl" id={`scene3D-section`} style={{ width: "100%" }}>
        <CanvasSelection />
      </div>
      {activeIndex !== undefined && (
        <Carrousel
          length={length}
          activeIndex={activeIndex}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Sections;
