// import { Splat } from "@react-three/drei";
import * as THREE from "three";
import { Splat } from "../common/3d/splat/splat";

const MySplat = () => {
  const { DEG2RAD } = THREE.MathUtils;
  return (
    <group
      name="gaussian splatting"
      rotation={[0, -13.3307 * DEG2RAD, 0]}
      // position={[0, -0.072061, 0]}
    >
      <Splat src="/pointclouds/tholos.splat" chunkSize={50000} />
    </group>
  );
};

export default MySplat;
