import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

const useQueriesNotice = (fragment, fn) => {
  // let numero = fragment.replace(",", "-");

  let numero = fragment;
  // console.log("useQueriesNotice", fragment);

  return useQuery({
    queryKey: [`notice_${numero}`],
    queryFn: async () => {
      const res = await fetch(`/xml/notices/${numero}.xml`);

      if (!res.ok) {
        throw new Error("pb côté serveur");
      }

      return res.text();
    },

    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
    onSuccess: fn,
  });
};
export default useQueriesNotice;
