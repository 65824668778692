import React from "react";
import {
  Box,
  Typography,
  Divider,
  Stack,
  Button,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { parseExternalUrl } from "../../../../outils";

const BulleLoading = (props) => {
  let url = new URL(props.target);

  console.log(url);

  return (
    <Box sx={{ p: 1 }}>
      <>
        <Stack>
          <Typography variant="overline" gutterBottom>
            <span>Chargement en cours</span>
          </Typography>
        </Stack>

        {/* <Divider /> */}
      </>

      <>
        <Stack sx={{ pt: 1 }}>
          <Box
            sx={{
              pb: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>

          <Typography variant="body2" paragraph align="justify">
            <span>{`Attente de réponse de ${url.hostname}`} </span>
          </Typography>
        </Stack>
        <Divider />
      </>

      <Stack sx={{ pt: 1 }} direction="row" alignItems="end">
        <Button
          size="small"
          startIcon={<OpenInNewIcon fontSize="small" />}
          target="_blank"
          href={url.origin}
          title={url.hostname}
          // variant="text"
        >
          archimage
        </Button>
      </Stack>
    </Box>
  );
};

export default BulleLoading;
