import React, { useEffect } from "react";

const Carrousel = ({ length, activeIndex, handlePageChange }) => {
  return (
    <div id="carrousel">
      {activeIndex === 0 ? null : (
        <div
          className="carrousel-button prev"
          onClick={() => handlePageChange(-1)}
        ></div>
      )}
      {activeIndex === length - 1 ? null : (
        <div
          className="carrousel-button next"
          onClick={() => handlePageChange(1)}
        ></div>
      )}
    </div>
  );
};

export default Carrousel;
