import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import useUiStore from "../../../stores/useUiStore";

import ModalTocText from "../../ui/modalTocText/modalTocTex";

const Texte = () => {
  const { section } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const paramsTexte = useUiStore((state) => state.paramsTexte);

  useEffect(() => {
    useUiStore.setState({
      paramsTexte: {
        ...paramsTexte,
        currentTexte: section ? section : "intro",
      },
    });
  }, [section]);

  const handleClose = () => {
    useUiStore.setState({
      paramsTexte: {
        ...paramsTexte,
        cardTexteOpen: true,
      },
    });

    location?.state?.from ? navigate(-1) : navigate("/");
  };

  return (
    <ModalTocText
      open={true}
      handleClose={handleClose}
      texte={section}
      contexte="texte"
    />
  );
};

export default Texte;
