import React, { useState, useEffect } from "react";
import * as jp from "jsonpath";

import useStyledXML from "./useStyledXML";

const useGetOccurences = ({ type, id }) => {
  const [occurences, setOccurences] = useState([]);

  const fragOptions = {
    ignoreAttributes: false,
    attributeNamePrefix: "",
    trim: true,
    stopNodes: ["*.head", "*.label"],
    processEntities: true,
    ignoreDeclaration: true,
    ignorePiTags: true,
    removeNSPrefix: true,
    isArray: (name, jpath, isLeafNode, isAttribute) => {
      if (["children", "div", "p", "ref"].indexOf(name) !== -1) return true;
    },
  };

  const sections = useStyledXML({
    xml: "texteGeneral",
    xslt: "tableOfContent",
    expr: `$..body..[?(@.subtype)]`,
    parserOptions: fragOptions,
  });

  const expressions = {
    opentheso: `$.*.p[*].index[?(@.source==\'https://opentheso.huma-num.fr/opentheso/?idc=${id}&idt=th653\')]`,
    tholos: `$.*.p[*].ref[?(@.target==\'https://tholos.huma-num.fr/#/fragments/${id}\')]`,
  };

  useEffect(() => {
    if (sections) {
      let res = jp.paths(sections, expressions[type]);
      let correspondances = [
        ...new Set(
          res
            .map((r) => ({
              subtype: sections[r[1]].subtype,
              head: sections[r[1]].head,
            }))
            .filter(
              (o, i, arr) => arr.findIndex((t) => t.subtype === o.subtype) === i
            )
        ),
      ];

      setOccurences(correspondances);
    }
  }, [sections, id]);

  return occurences;
};

export default useGetOccurences;
