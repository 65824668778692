import React, { useRef, useEffect, useState, useMemo } from "react";
import * as THREE from "three";
import { Canvas, useThree } from "@react-three/fiber";
import { Perf } from "r3f-perf";

import { useProgress } from "@react-three/drei";

import { Route, Router, useLocation } from "wouter";
import { useNavigate } from "react-router-dom";
import Woutes from "../../routes/woutes";

import Carrousel from "../../ui/carrousel/carrousel";

import {
  FragmentDummy,
  FragmentPreview,
  FragmentModel,
  MyEffects,
  MyEnvironment,
  MyLights,
  MySplat,
} from "./index";

import Annotations from "./annotations/annotations";

import Overlay from "../../ui/loader/overlay";

import useModelStore from "../../../stores/useModelStore";
import useKeys from "../../hooks/useKeys";

const loadingBarElement = document.querySelector(".loading-bar");

const CanvasFragment = (props) => {
  const {
    name,
    width,
    fitToSphere,
    couche,
    rotationFragment,
    annotationsOn,
    setOpenNotice,
    openNotice,
    isFragment,
    isModele,
    numero,
  } = props;

  const [count, setCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState();
  const [, setLocation] = useLocation();
  const navigate = useNavigate();
  const progress = useProgress((state) => state.progress);
  const item = useProgress((state) => state.item);

  const currentSelection = useModelStore((state) => state.currentSelection);
  const allFragments = useModelStore((state) => state.fragments)
    .filter((f) => f.gltf === true)
    .map((f) => f.numero_inventaire_3d);

  let listFragments = currentSelection.includes(name)
    ? currentSelection
    : allFragments;

  const handlePageChange = (value) => {
    let previousIndex = activeIndex + value;
    if (previousIndex >= 0 && previousIndex < listFragments.length) {
      setActiveIndex((currentIndex) => currentIndex + value);
      let numero = listFragments[activeIndex + value].replace("-", ",");
      navigate(`/fragments/${numero}`);
    }
  };

  useKeys("ArrowRight", () => handlePageChange(1));
  useKeys("ArrowLeft", () => handlePageChange(-1));

  useEffect(() => {
    if (listFragments?.length > 0) {
      const index = listFragments.indexOf(name);
      setActiveIndex(index);
    }
  }, [listFragments?.length, name]);

  let ratio = 0;
  let score = 0;
  // useEffect(() => {
  //   ratio = progress / 100;
  //   loadingBarElement.classList.remove("ended");
  //   loadingBarElement.style.transform = `scaleX(${ratio})`;

  //   ratio >= 1
  //     ? window.setTimeout(() => {
  //         loadingBarElement.classList.add("ended"),
  //           (loadingBarElement.style.transform = "");
  //       }, 500)
  //     : null;

  //   // progress >= 100
  //   //   ? window.setTimeout(() => {
  //   //       (loadingBarElement.style.transform = "scaleX(0)"),
  //   //         // (loadingBarElement.style.transformOrigin = "top right"),
  //   //         //(loadingBarElement.style.transition ="transform 1.5s ease-in-out");

  //   //         loadingBarElement.classList.add("ended"),
  //   //         (loadingBarElement.style.transform = "");
  //   //     }, 250)
  //   //   : (loadingBarElement.classList.remove("ended"),
  //   //     (loadingBarElement.style.transform = `scaleX(${ratio})`));
  //   // // (loadingBarElement.style.transformOrigin = "top left"),
  //   // loadingBarElement.style.transition = "transform 0.5s";
  // }, [progress]);

  const MyScene = () => {
    const { scene, camera } = useThree();

    scene.name = `scene_${name}`;
  };

  return (
    <div
      className="webgl"
      id="scene3D-fragment"
      style={{ width: `calc(100% - ${width})` }}
    >
      <Canvas
        flat
        legacy={true}
        linear={true}
        shadows
        dpr={[1, 2]}
        camera={{
          fov: 20,
          near: 0.01,
          far: 1500,
          position: [0, 0, 0.33],
        }}
      >
        {/* <Perf position="bottom-right" /> */}
        {/* <MyEffects /> */}
        {/* {isFragment ? <Overlay /> : null} */}
        {/* <MySplat /> */}
        <MyScene />
        <MyLights />

        {isFragment && isModele ? (
          <>
            <FragmentModel
              filename={name}
              fitToSphere={fitToSphere}
              couche={couche}
              rotationFragment={rotationFragment}
              annotationsOn={annotationsOn}
              setOpenNotice={setOpenNotice}
              openNotice={openNotice}
            />
            {/* <Annotations /> */}
          </>
        ) : (
          <FragmentDummy filename={name} />
        )}
        {/* <MyEnvironment /> */}
      </Canvas>
      <Carrousel
        length={listFragments.length}
        activeIndex={activeIndex}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};
export default CanvasFragment;
