import React, { useRef, useState, useEffect, useMemo } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import useUiStore from "../../../../stores/useUiStore";

export default function ModelPreview(props) {
  const { filename, hovered, cellSize, rotationFragment } = props;
  const [radius, setRadius] = useState();

  const { paramsFragments } = useUiStore();

  var pathToModel = `/modeles/${filename}/5K/${filename}.glb`;
  var pathToDraco = "/draco/";

  const matRef = useRef();
  const meshRef = useRef();
  const myScaleRef = useRef();
  // let myScale;

  const orange = new THREE.Color("orange");
  const goldenrod = new THREE.Color("goldenrod");
  const gold = new THREE.Color("gold");
  const yellow = new THREE.Color("yellow");
  const white = new THREE.Color("white");
  const navajowhite = new THREE.Color("navajowhite");

  const vecScaleInit = new THREE.Vector3();
  const vecScaleFill = new THREE.Vector3();

  const [rotX, rotY, rotZ] = rotationFragment.map((item) =>
    THREE.MathUtils.degToRad(item)
  );

  useEffect(() => {
    const bbox = new THREE.Box3().setFromObject(meshRef.current);

    const bboxSize = new THREE.Vector3();
    const { x, y, z } = bbox.getSize(bboxSize);

    const maxSize = Math.max(x, y, z);

    const coef = 0.6;

    myScaleRef.current = (cellSize / maxSize) * coef;
    const scale = (cellSize / maxSize) * coef;
    paramsFragments?.scaled?.value
      ? meshRef.current.scale.setScalar(1)
      : meshRef.current.scale.setScalar(scale);
  }, [paramsFragments?.scaled?.value]);

  // Loader
  const model = useGLTF(pathToModel, pathToDraco);
  const { nodes, materials } = model;

  const listMeshes = Object.values(nodes).filter((node) => node.isMesh);
  const material = Object.values(materials).find((mat) => mat.isMaterial);
  material.name = `${filename}_5K_mat`;

  useFrame((state, delta) => {
    // color
    matRef.current.color.lerp(hovered ? navajowhite : white, 0.3);

    // rotation
    paramsFragments?.animated?.value
      ? (meshRef.current.rotation.y += delta / 12)
      : (meshRef.current.rotation.y = THREE.MathUtils.lerp(
          meshRef.current.rotation.y,
          Math.round(meshRef.current.rotation.y / (Math.PI * 2)) * Math.PI * 2,
          0.05
        ));
  });

  return (
    <group
      {...props}
      dispose={null}
      name={`${filename}_5K`}
      rotation={[rotX, rotZ, rotY]}
    >
      {listMeshes.map((mesh, index) => (
        <mesh
          key={index}
          geometry={mesh.geometry}
          ref={meshRef}
          name={`${filename}_5K_mesh`}
        >
          <meshStandardMaterial ref={matRef} map={material.map} />
        </mesh>
      ))}
    </group>
  );
}
