import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  CardActions,
  Button,
  IconButton,
  Box,
  ClickAwayListener,
  Avatar,
  Tooltip,
} from "@mui/material";

import useUiStore from "../../../stores/useUiStore";

import CloseIcon from "@mui/icons-material/Close";
import HubIcon from "@mui/icons-material/Hub";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useFetch, useSections } from "../../hooks";

import TexteGeneral from "../../chapitres/texteGeneral/texteGeneral";

const CardText = (props) => {
  const { open } = props;

  const paramsTexte = useUiStore((state) => state.paramsTexte);

  const { data: stations } = useFetch({ url: "/json/stations.json" });

  const section = useSections(paramsTexte.currentTexte);

  let hasFragments = section?.p?.some((p) => p.ref);

  const navigate = useNavigate();
  const location = useLocation();

  const setCurrentTexte = (subtype) => {
    useUiStore.setState({
      paramsTexte: { ...paramsTexte, currentTexte: subtype },
    });
  };

  const [id, setId] = useState(paramsTexte.currentTexte || "intro");

  const handleId = (id) => {
    setId(id);
  };

  const handleClose = () => {
    useUiStore.setState({
      paramsTexte: { ...paramsTexte, cardTexteOpen: false },
    });
  };

  const handleMaximizeText = () => {
    navigate(`/texte/${paramsTexte.currentTexte}`, {
      state: { from: location.pathname },
    });
  };

  const handleClickFragments = () => {
    navigate(`/fragments/${paramsTexte.currentTexte}`);
  };

  const handleClickTholos = () => {
    navigate(`/tholos/${paramsTexte.currentTexte}`);
  };

  const [selectedItems, setSelectedItems] = useState(null);

  const width = 600;

  const boxStyle = {
    columnCount: 2,
    columnFill: "auto",
    columnGap: "30px",
  };

  return (
    <div
      style={{
        position: "absolute",
        maxWidth: `${width}px`,
        maxHeight: "100%",
        zIndex: 2000,
        right: paramsTexte.cardTexteOpen ? 0 : -width,
        transition: "all 0.25s ease-out",
        // padding: "10px",
      }}
    >
      <Box>
        <Card sx={{ minWidth: width, height: "100vh" }}>
          <CardHeader
            title="Texte général"
            avatar={
              <Avatar>
                <Tooltip title="Afficher le texte en plein écran" arrow>
                  <IconButton onClick={handleMaximizeText}>
                    <OpenInFullIcon sx={{ transform: "scale(-1,1)" }} />
                  </IconButton>
                </Tooltip>
              </Avatar>
            }
            subheader="Le décor sculpté de la Tholos de Marmaria"
            action={
              <IconButton aria-label="settings" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
            sx={{ pb: 1 }}
          />
          <CardContent sx={{ pt: 1 }}>
            <Box sx={{ m: 1 }}>
              <TexteGeneral
                id={paramsTexte.currentTexte}
                handleId={handleId}
                setId={setId}
                setSelectedItems={setSelectedItems}
                setCurrentTexte={setCurrentTexte}
                boxStyle={boxStyle}
              />
            </Box>
          </CardContent>
          <CardActions sx={{ p: 2 }}>
            <Button
              size="small"
              startIcon={<AccountBalanceIcon fontSize="small" />}
              onClick={handleClickTholos}
              disabled={
                stations?.map((s) => s.id).includes(paramsTexte.currentTexte)
                  ? false
                  : true
              }
            >
              Explorer le monument
            </Button>
            <Button
              size="small"
              startIcon={<HubIcon fontSize="small" />}
              onClick={handleClickFragments}
              disabled={hasFragments ? false : true}
            >
              Fragments mentionnés
            </Button>
          </CardActions>
        </Card>
      </Box>
    </div>
  );
};

export default CardText;
