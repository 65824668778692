import React, { useState, useEffect } from "react";
import { Box, Fab, Badge } from "@mui/material";

import useUiStore from "../../../../stores/useUiStore";

import CustomFab from "../../../ui/fabs/customFab";

import TuneIcon from "@mui/icons-material/Tune";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";

const Fabs = (props) => {
  const { handleSettings, openSettings } = props;

  const [fullscreen, setFullscreen] = useState(
    Boolean(document.fullscreenElement)
  );
  const backgroundColor = "#FFFFFF66";

  const updateRandom = useUiStore((state) => state.updateRandom);

  const handleFullScreenChange = () => {
    Boolean(document.fullscreenElement)
      ? (document.exitFullscreen(), setFullscreen(false))
      : (document.body.requestFullscreen(), setFullscreen(true));
  };
  const fabStyle = { backgroundColor: backgroundColor };

  const fabs = [
    {
      title: "Réglages",
      onClick: handleSettings(true),
      icon: <TuneIcon />,
      style: { backgroundColor: openSettings ? "#FFFFFF" : backgroundColor },
    },
    {
      title: "Placement aléatoire",
      onClick: updateRandom,
      icon: <AutorenewIcon />,
      style: fabStyle,
    },
    {
      title: Boolean(document.fullscreenElement)
        ? "Sortir du plein écran"
        : "Plein écran",
      onClick: handleFullScreenChange,
      icon: Boolean(document.fullscreenElement) ? (
        <FullscreenExitIcon />
      ) : (
        <FullscreenIcon />
      ),
      style: fabStyle,
    },
  ];

  return (
    <Box
      sx={{
        pointerEvents: "none",
        "& > :not(style)": { m: 1, pointerEvents: "auto" },
      }}
      id="fabs"
    >
      {fabs.map(
        (fab, i) =>
          (fab.modeAdmin == null || fab.modeAdmin === config?.modeAdmin) && (
            <CustomFab key={i} {...fab}>
              {fab.icon}
            </CustomFab>
          )
      )}
    </Box>
  );
};

export default Fabs;
