import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Stack, Grid, Button } from "@mui/material";

import { useRoute, useLocation, Route, Router } from "wouter";

import * as jp from "jsonpath";

import useStyledXML from "../../hooks/useStyledXML";
import { xmlParserOptions as biblioOptions } from "../bibliographieGenerale/xmlParserOptions";
import { xmlParserOptions as texteOptions } from "./xmlParserOptions";

import { truncateSentence } from "../../../outils";

import Paragraphe from "../notices/paragraphe";

const TexteGeneral = (props) => {
  const { id, setId, setSelectedItems, setCurrentTexte, boxStyle } = props;
  const [text, setText] = useState([]);

  const [fragments, setFragments] = useState([]);

  let params = { id: id };

  const fragOptions = {
    ignoreAttributes: false,
    attributeNamePrefix: "",
    trim: true,
    stopNodes: ["*.head", "*.label"],
    processEntities: true,
    ignoreDeclaration: true,
    ignorePiTags: true,
    removeNSPrefix: true,
    isArray: (name, jpath, isLeafNode, isAttribute) => {
      if (["children", "div", "p", "ref"].indexOf(name) !== -1) return true;
    },
  };

  const txt = useStyledXML({
    xml: "texteGeneral",
    xslt: "tableOfContent",
    expr: `$..[?(@.type=="section1")]`,
    parserOptions: texteOptions,
  });

  const bib = useStyledXML({
    xml: "bibliographie",
    xslt: "tableOfContent",
    expr: `$..body..bibl[*]`,
    parserOptions: biblioOptions,
  });

  const frag = useStyledXML({
    xml: "texteGeneral",
    xslt: "tableOfContent",
    // expr: `$..body..ref[*].target`,
    // expr: `$..[?(@.subtype=="${params?.id}")]..ref[*].target`,
    // expr: `$..[?(@.subtype)]..ref[*]`,
    expr: `$..body..[?(@.subtype)]`,
    parserOptions: fragOptions,
  });

  const ScrollBox = ({ children }) => {
    return (
      <Box
        sx={{
          scrollSnapAlign: "start",
        }}
      >
        {children}
      </Box>
    );
  };

  useEffect(() => {
    if (params?.id && txt && id !== "bibliographie") {
      let [res] = jp.query(txt, `$..[?(@.subtype=="${params?.id}")]`);

      setText(res);
    }
  }, [params?.id, txt, bib]);

  useEffect(() => {
    if (params?.id && frag && id !== "bibliographie") {
      let res = jp.query(
        frag,
        `$..[?(@.subtype=="${params?.id}")]..ref[*].target`
      );
    }
  }, [id, frag]);

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          component="div"
          dangerouslySetInnerHTML={
            id === "bibliographie"
              ? { __html: "Bibliographie" }
              : { __html: text?.head }
          }
          gutterBottom
        />
      </Box>

      <Box
        sx={{
          height: text?.div?.length
            ? `calc(75vh - 6* ${text?.div?.length}vh)`
            : "75vh",

          overflowX: "auto",
          "&::-webkit-scrollbar": {
            width: "2px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#00000050",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "00000050",
            backgroundColor: "#555",
            borderRadius: "2px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#888",
          },
          scrollBehavior: "smooth",
          scrollSnapType: "x mandatory",
          ...boxStyle,
        }}
      >
        {id === "bibliographie"
          ? bib?.map((p, i) => (
              <ScrollBox key={i}>
                <Paragraphe key={i} id={p.id} text={p[`#text`]} />
              </ScrollBox>
            ))
          : text?.p?.map((p, i) => (
              <ScrollBox key={i}>
                <Paragraphe key={i} id={p.id} text={p[`#text`]} section={id} />
              </ScrollBox>
            ))}
      </Box>

      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        justifyContent="center"
        alignItems="center"
      >
        {text?.div &&
          text?.div.map((div, i) => (
            <Grid item xs={3} key={i}>
              <Button
                key={i}
                size="small"
                onClick={() => setCurrentTexte(div.subtype)}
              >
                {div.head}

                {/* {truncateSentence(div.head, 3)} */}
              </Button>
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};

export default TexteGeneral;
