import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocation as useWocation, useRoute as useWoute } from "wouter";
import useHashLocation from "../hooks/useHashLocation";
import useUiStore from "../../stores/useUiStore";
import { useTheme } from "@mui/material/styles";
import { useColors } from "../hooks";

const TemplateMetopes = (props) => {
  const { id, title, paths, MyTooltip } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [loc] = useWocation();
  const [locHash] = useHashLocation();

  const [hovered, setHovered] = useState(false);
  const [selected, setSelected] = useState(false);

  const { base, architrave } = useColors();

  const fill = hovered
    ? base.fill.hovered
    : selected
    ? base.fill.selected
    : base.fill.idle;
  const stroke = hovered
    ? base.stroke.hovered
    : selected
    ? base.stroke.selected
    : base.stroke.idle;

  const fillArchitrave = hovered
    ? architrave.fill.hovered
    : architrave.fill.idle;

  const strokeArchitrave = architrave.stroke.idle;

  const paramsTexte = useUiStore((state) => state.paramsTexte);

  const handleClick = () => {
    navigate(`/tholos/${id}`);
    useUiStore.setState({ paramsTexte: { ...paramsTexte, currentTexte: id } });
  };

  useEffect(() => {
    let path = import.meta.env.VITE_NODE_ENV === "production" ? locHash : loc;
    if (path === `/tholos/${id}`) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [loc, locHash, location]);

  const handleMouseOver = (e) => {
    e.stopPropagation();
    setHovered(true);
  };
  const handleMouseOut = (e) => {
    e.stopPropagation();
    setHovered(false);
  };

  return (
    <MyTooltip title={title}>
      <g
        id={id}
        className="metopes"
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <path
          fill={fillArchitrave}
          stroke={strokeArchitrave}
          className="architraves"
          id={`path-architraves-${id}`}
          d={paths.architrave}
        />
        <path
          className="metopes"
          id={`path-${id}`}
          fill={fill}
          stroke={stroke}
          transform="rotate(4.5)"
          d={paths.metopes}
        />
        {/* <title>{title}</title> */}
      </g>
    </MyTooltip>
  );
};

export default TemplateMetopes;
