import React from "react";
import { Tooltip } from "@mui/material";

import parse, { attributesToProps, domToReact } from "html-react-parser";

import BulleFragment from "./bulleFragment";

import options from "../common/options";

const TooltipFragment = (props) => {
  const { id, text, fragment } = props;

  //   let parsedReference = parse(reference, options);

  //   console.log(reference, parsedReference);

  return (
    <Tooltip
      key={id}
      title={
        fragment ? (
          <BulleFragment fragment={fragment} section={props.section} />
        ) : null

        // parsedReference ? <BulleReferenceBiblio text={parsedReference} /> : null
      }
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "#111111",
            "& .MuiTooltip-arrow": {
              color: "#111111",
            },
          },
        },
      }}
      arrow
      leaveDelay={250}
    >
      <mark className="fragment-3d" id={`fragment-${id}`}>
        {text}
      </mark>
    </Tooltip>
  );
};

export default TooltipFragment;
