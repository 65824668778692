import React, {
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useRoute, useLocation, Route } from "wouter";
import * as THREE from "three";
import { useFrame, createPortal, useThree } from "@react-three/fiber";
import {
  useCursor,
  CameraControls,
  useFBO,
  ScreenQuad,
} from "@react-three/drei";
// import { CrossFadeMaterial } from "./common/xFadeMaterial";

import useKeys from "../../hooks/useKeys";
import useModelStore from "../../../stores/useModelStore";
import useUiStore from "../../../stores/useUiStore";

import { normalizeString } from "../../../outils";
import Composition from "./common/composition";

function RenderScene({ target, camRef, ...props }) {
  const scene = useMemo(() => new THREE.Scene(), []);
  useFrame((state) => {
    state.gl.setRenderTarget(target);
    state.gl.render(scene, camRef.current);
  }, 0);
  return createPortal(
    <group position={position}>
      <boxGeometry args={[0.6, 0.6, 0.07]}></boxGeometry>
    </group>,
    scene
  );
}

const Collection = (props) => {
  const {
    name,
    filename,
    position,
    positions,
    positionSupport,
    fragments,
    anchorText,
  } = props;

  const [, params] = useRoute("/:id");
  const [location, setLocation] = useLocation();
  const [zoom, setZoom] = useState(false);
  const [activeIndex, setActiveIndex] = useState();

  const setCurrentSelection = useModelStore(
    (state) => state.setCurrentSelection
  );

  const paramsTexte = useUiStore((state) => state.paramsTexte);

  const clickedRef = useRef();

  const compositionRefs = Array.from({ length: positions.length }, () =>
    React.createRef(null)
  );

  const collectionRef = useRef();
  const collection = fragments.map((f) => normalizeString(f?.title || f.name));
  const compositionRef = useRef();

  setCurrentSelection(fragments.map((f) => f.name));

  const cameraControlsRef = useRef();

  const t0 = useFBO({ stencilBuffer: false, multisample: true });
  const t1 = useFBO({ stencilBuffer: false, multisample: true });
  const targets = [t0, t1];
  const camRef = useRef(null);

  useFrame((state) => {
    state.gl.setRenderTarget(null);
    state.gl.render(state.scene, state.camera);
  }, 1);

  function get3DPosition(mesh) {
    if (!mesh) {
      return;
    }

    let pos = new THREE.Vector3();
    const center = new THREE.Vector3();
    const box = new THREE.Box3().setFromObject(mesh);
    pos = box.getCenter(center);
    return pos;
  }

  const handleClick = (e) => {
    e.stopPropagation();
    clickedRef.current = e.object.parent.userData.uri;
    setLocation(`/${clickedRef.current}`);
  };

  const handlePointerMissed = (e) => {
    e.stopPropagation();
    setLocation("");
  };

  const handleSelection = useCallback(
    (event) => {
      if (!zoom) return;

      const { key } = event;
      const arrowRightPressed = key === "ArrowRight";
      const arrowLeftPressed = key === "ArrowLeft";
      const length = positions.length - 1;

      if (arrowLeftPressed) {
        event.preventDefault();
        setActiveIndex((currentIndex) =>
          currentIndex - 1 >= 0 ? currentIndex - 1 : length
        );
      } else if (arrowRightPressed) {
        event.preventDefault();
        setActiveIndex((currentIndex) =>
          currentIndex + 1 <= length ? currentIndex + 1 : 0
        );
      }
    },
    [zoom, clickedRef.current]
  );

  useEffect(() => {
    useUiStore.setState({
      paramsTexte: { ...paramsTexte, currentTexte: name },
    });
  }, [name]);

  useEffect(() => {
    if (activeIndex !== undefined && zoom) {
      let path = compositionRefs[activeIndex]?.current.userData.uri;
      setLocation(`/${path}`);
    }
  }, [activeIndex]);

  useEffect(() => {
    document.addEventListener("keydown", handleSelection);
    return () => {
      document.removeEventListener("keydown", handleSelection);
    };
  }, [handleSelection]);

  useEffect(() => {
    let controls = cameraControlsRef.current;
    let compositions = collectionRef.current.children;
    let currentComposition = compositions.find(
      (f) => normalizeString(f.name) === params?.id
    );
    compositionRef.current = currentComposition;

    if (currentComposition) {
      setActiveIndex(currentComposition.compositionID);
      setTimeout(() => {
        controls.fitToSphere(currentComposition, true);
      }, 0);

      setZoom(true);
    } else {
      setZoom(false);
      setActiveIndex(null);
      setTimeout(() => {
        controls.fitToSphere(collectionRef.current, true);
      }, 100);
    }
  }, [params?.id]);

  useKeys("ArrowDown", () => {
    let random = Math.floor(Math.random() * positions.length);
    let path = compositionRefs[random].current.userData.uri;
    setLocation(`/${path}`);
  });

  return (
    <Route path="/" nest>
      <CameraControls
        ref={cameraControlsRef}
        enabled
        azimuthRotateSpeed={0.25}
        polarRotateSpeed={0.25}
        minDistance={2}
        maxDistance={20}
        minPolarAngle={Math.PI / 2}
        maxPolarAngle={Math.PI / 2}
        minAzimuthAngle={0}
        maxAzimuthAngle={0}
      />
      <group
        position={position}
        name={`collection-${name}`}
        ref={collectionRef}
        onPointerMissed={handlePointerMissed}
      >
        {positions.map((p, i) => (
          <Composition
            key={i}
            compositionID={i}
            position={p}
            filename={filename}
            positionSupport={positionSupport}
            shift={position}
            ref={compositionRefs[i]}
            handleClick={handleClick}
            fragments={fragments[i] || { title: `composition-${i}` }}
            zoom={zoom}
            anchorText={anchorText}
          />
        ))}
      </group>
    </Route>
  );
};
export default Collection;
