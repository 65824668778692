import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  CardActions,
  Button,
  IconButton,
  Box,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import TableDesMatieres from "../../chapitres/tableDesMatieres/tableDesMatieres";

const CardToc = (props) => {
  //   const { handleClose } = props;
  return (
    <Card>
      <CardHeader title="Table des matières" />
      <CardContent sx={{ p: 1 }}>
        <TableDesMatieres />
      </CardContent>
    </Card>
  );
};

export default CardToc;
