import React from "react";
import { Tooltip } from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import useQueries from "../../../hooks/useQueries";

import BulleArchimageIIIF from "./bulleArchimageIIIF";
import BulleLoading from "./bulleLoading";

const TooltipArchimageIIIF = (props) => {
  const { id, text, target } = props;

  let url = `https://archimage.efa.gr/action.php?kroute=document_export_json_public&id=${id}`;
  let urlIIIF = `https://archimage.efa.gr/action.php?r=iiif_json_public&id=${id}`;

  const { data, isSuccess } = useQueries(url);

  return (
    <Tooltip
      key={id}
      title={
        isSuccess && data ? (
          <BulleArchimageIIIF {...data} target={target} />
        ) : (
          <BulleLoading target={target} />
        )
      }
      arrow
      leaveDelay={250}
    >
      <mark className="archimage-iiif" id={`archimage-iiif-${id}`}>
        {text}
      </mark>
    </Tooltip>
  );
};

export default TooltipArchimageIIIF;
