import React, { useState } from "react";
import { Box } from "@mui/material";
import CardToc from "./cardToc";

const CardTocWrapper = (props) => {
  const { open, handleClose } = props;
  return (
    <div
      style={{
        position: "absolute",
        maxWidth: "600px",
        maxHeight: "100%",
        zIndex: 2000,
        top: 42,
        left: 24,
        transition: "all 0.25s ease-out",

        visibility: open ? "visible" : "hidden",
        opacity: open ? 1 : 0,
      }}
    >
      <Box onMouseLeave={handleClose}>
        <CardToc handleClose={handleClose} />
      </Box>
    </div>
  );
};

export default CardTocWrapper;
