import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import TholosCanvas from "./tholosCanvas";
import TholosTextes from "./tholosTextes";
import Footer from "./ui/footer";
import SideBar from "./ui/sideBar";
import SettingsCard from "./ui/settingCard";

import useUiStore from "../../../stores/useUiStore";

import CurrentWoute from "../common/router/currentWoute";

const Tholos3D = () => {
  const [textVisible, setTextVisible] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const location = useLocation();

  const paramsTexte = useUiStore((state) => state.paramsTexte);
  const config = useUiStore((state) => state.config);

  const titres = {
    // elements: "Les éléments du décor sculpté de la Tholos",
    "grandes-metopes": "Les grandes métopes",
    "petites-metopes": "Les petites métopes",
    acroteres: "Les acrotères",
  };

  const openText = () => {
    setTextVisible(true);
  };

  const closeText = () => {
    setTextVisible(false);
  };

  const handleSettings = (value) => (e) => {
    setOpenSettings(value);
  };

  const fabsHandlers = {
    handleText: openText,
  };

  const VanillaTooltip = () => {
    return (
      <div className="point point-0">
        <div className="labelBulle">1</div>
        <div className="text">test</div>
      </div>
    );
  };

  return (
    <CurrentWoute path="/tholos">
      <div className="sur-titre">Les éléments sculptés de la Tholos</div>

      <div className="titre">{titres?.[paramsTexte.currentTexte]}</div>
      <TholosCanvas />
      <TholosTextes textVisible={textVisible} closeText={closeText} />
      {/* <VanillaTooltip /> */}
      <div
        id="reglages-fragments"
        sx={{ visibility: openSettings ? "visible" : "hidden" }}
      >
        <SettingsCard open={openSettings} handleSettings={handleSettings} />
      </div>

      {/* <SideBar {...fabsHandlers} /> */}
      <Footer openSettings={openSettings} handleSettings={handleSettings} />
    </CurrentWoute>
  );
};

export default Tholos3D;
