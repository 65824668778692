import { Canvas, useThree } from "@react-three/fiber";
import {
  useProgress,
  CameraControls,
  BakeShadows,
  AccumulativeShadows,
  RandomizedLight,
} from "@react-three/drei";
import { Link } from "react-router-dom";
import { useRoute, useLocation, Route, Router } from "wouter";

import { Suspense, useEffect, useRef, useMemo, memo } from "react";

import useHashLocation from "../../hooks/useHashLocation";

import useUiStore from "../../../stores/useUiStore";

import Woutes from "../../routes/woutes";

import Tholos from "./tholos";
import PointCloud from "./pointcloud";
import MySplat from "./mySplat";
import Lights from "./lights";
import Ground from "./ground";
import Shadows from "./shadows";
import Fragments from "./fragments";

import { CustomLoader } from "../../model";

const Scene = (props) => {
  const { scene } = useThree();
  scene.name = "Accueil Tholos";

  const paramsTholos = useUiStore((state) => state.paramsTholos);
  const config = useUiStore((state) => state.config);

  return (
    <>
      <Tholos {...props} />
      {!paramsTholos.reconstitution.value &&
      !paramsTholos.anastyloseSite.value ? (
        <Shadows />
      ) : null}

      {paramsTholos.splat.value ? <MySplat /> : null}

      <Lights />
    </>
  );
};

const TholosCanvas = () => {
  const init = {
    maxDistance: 30,
    minDistance: 12,
    fov: 50,
    near: 0.05,
    far: 1500,
  };
  return (
    <div className="webgl">
      <Canvas
        name="Tholos3D"
        //id="scene3D"
        shadows
        camera={{
          fov: init.fov,
          near: init.near,
          far: init.far,
          position: [0, 30, 0],
        }}
        // flat
        dpr={[1, 2]}
      >
        <Suspense fallback={<CustomLoader />}>
          {/* <CurrentRoute /> */}
          <Scene init={init} />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default TholosCanvas;
