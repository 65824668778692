import React from "react";
import { Chip } from "@mui/material";
import { Link } from "react-router-dom";
import useDefinitionStore from "../../../../stores/useDefinitionStore";

const ChipAnnotation = (props) => {
  const { label, hex, couche } = props;

  const setActiveColor = useDefinitionStore((state) => state.setActiveColor);

  const handleMouseOver = (e) => {
    e.stopPropagation();
    useDefinitionStore.setState({
      currentCouche: couche,
      noticeActive: true,
      mouseActive: true,
    });
    setActiveColor(hex, couche);
  };

  const handleMouseOut = (e) => {
    console.log("mouse out");
    e.stopPropagation();
    useDefinitionStore.setState({
      noticeActive: false,
      mouseActive: false,
    });
    setActiveColor("000000", couche);
  };

  return (
    <Chip
      label={label}
      variant="filled"
      size="small"
      component={Link}
      sx={{ m: 0.5 }}
      // to={`/fragments/${couche}/${hex}`}
      to={`${props.url}`}
      target="_blank"
      clickable
      // onMouseEnter={handleMouseOver}
      // onMouseLeave={handleMouseOut}
      title="redirection provisoire"
    />
  );
};
export default ChipAnnotation;
