import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { mountStoreDevtool } from "simple-zustand-devtools";

import { XMLParser } from "fast-xml-parser";
import parserOptions from "../components/chapitres/thesaurii/common/parserOptions";

const parser = new XMLParser(parserOptions);

const useThesoStore = create(
  subscribeWithSelector((set) => ({
    index: { materialite: [], iconographie: [] },
    theso: [],
    isLoaded: false,
    setTheso: (theso) => set((state) => ({ ...state, theso })),
    setIsLoaded: (isLoaded) => set((state) => ({ ...state, isLoaded })),
    opentheso: [],
    thesaurii: { materialite: [], iconographie: [] },
  }))
);

let openthesoURL;
let thesaurus;

// https://vocab.e-rihs.io/openapi/doc/

let apiURL = "https://opentheso.huma-num.fr/opentheso/api";

// let apiURL = "https://opentheso.huma-num.fr/openapi/v1/thesaurus";

//https://opentheso.huma-num.fr/openapi/v1/expansion/concept?theso=th653&id=1&way=down
//https://opentheso.huma-num.fr/opentheso/api/expansion/concept?theso=th653&id=1&way=down

let idt = "th653";
// let expansionOld = `/expansion/concept?theso=${idt}&id=1&way=down`;

// let expansion = `/concept/${idt}/${idc}/expansion`;

let thesaurusOnline = [
  {
    id: 1,
    label: "iconographie",
    url: `${
      import.meta.env.VITE_OPENTHESO_API
      // apiURL
    }/expansion/concept?theso=${idt}&id=1&way=down&format=rdf`,
  },
  {
    id: 2,
    label: "materialite",
    url: `${
      import.meta.env.VITE_OPENTHESO_API
      // apiURL
    }/expansion/concept?theso=${idt}&id=2&way=down&format=rdf`,
  },
];

let thesaurusOffline = [
  {
    id: 1,
    label: "iconographie",
    url: "/opentheso/th653_id1_waydown.rdf",
  },
  {
    id: 2,
    label: "materialite",
    url: "/opentheso/th653_id2_waydown.rdf",
  },
];

switch (import.meta.env.VITE_OPENTHESO_STATUS) {
  case "online":
    openthesoURL = `${
      import.meta.env.VITE_OPENTHESO_API
    }/all/theso?id=${idt}&format=rdf`;

    thesaurus = thesaurusOnline;
    break;
  case "offline":
    openthesoURL = "/opentheso/th653.rdf";
    thesaurus = thesaurusOffline;
    break;
}

const myHeaders = new Headers({
  //"API-KEY": import.meta.env.VITE_OPENTHESO_TOKEN,
  "Content-Type": "application/rdf+xml",
  Accept: "application/rdf+xml",
  crossorigin: true,
  // "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET",
  "Access-Control-Request-Headers": "Content-Type",
  // Origin: "https://tholos.archeo3d.net",
  Origin: import.meta.env.VITE_HOST_THOLOS,
  Referer: import.meta.env.VITE_HOST_THOLOS,
});

/**
 *  Tout le thésaurus
 *
 */

fetch(openthesoURL)
  .then((res) => res.text())
  .then((text) => parser.parse(text))
  .then((data) => data.RDF.Description)
  .then((data) => data.filter((c) => c.identifier))
  .then((data) => {
    useThesoStore.setState((state) => ({ ...state, opentheso: data }));
  });

/**
 *
 *
 * TESTS
 */

let url1 =
  "https://opentheso.huma-num.fr/api/expansion/concept?theso=th653&id=1&way=down&format=rdf";
let url2 = "https://opentheso.huma-num.fr/api/all/theso?id=th653&format=rdf";
let url3 =
  "https://opentheso.huma-num.fr/openapi/v1/concept/th653/1/expansion?way=down&format=rdf";

async function fetchThesaurus(url) {
  try {
    const response = await fetch(url, {
      headers: myHeaders,
      mode: "cors",
      // signal: AbortSignal.timeout(15000),
    });
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }
    console.log(`reponse ${url}`, response.status);
    const data = await response.text();
    // console.log(data);
  } catch (error) {
    console.error(`réponse à l'url ${url} :`, error);
  }
}

// fetchThesaurus(url3);
// fetchThesaurus(url2);

// fetch(

//   {
//     headers: {
//       Accept: "application/rdf+xml",
//       "Content-Type": "application/rdf+xml",
//       "access-control-allow-origin": "*",
//       crossorigin: true,
//       "Access-Control-Allow-Methods": "GET",
//     },
//   }
// )
//   .then((res) => res.text())
//   .then((text) => parser.parse(text))
//   .then((data) => console.log(data));

// fetch(
//   "https://opentheso.huma-num.fr/api/expansion/concept?theso=th653&id=1&way=down&format=rdf",
//   {
//     headers: {
//       Accept: "application/rdf+xml",
//       "Content-Type": "application/rdf+xml",
//     },

//   }
// )
//   .then((res) => res.text())

//   .then((text) => parser.parse(text))
//   .then((data) => console.log(data));

/**
 *  thésaurus par branche
 *
 */

thesaurus.map((th) =>
  fetch(th.url, {
    headers: myHeaders,
    method: "GET",
  })
    .then((res) => res.text())
    .then((text) => parser.parse(text))
    // .then((data) => console.log(data))
    .then((data) => data.RDF.Description)
    .then((data) => data.filter((c) => c.identifier))
    .then((data) =>
      useThesoStore.setState((state) => ({
        ...state,
        thesaurii: { ...state.thesaurii, [`${th.label}`]: data },
        index: {
          ...state.index,
          [`${th.label}`]: data.map((d) => d.identifier[`#text`]),
        },
      }))
    )
);

if (import.meta.env.VITE_NODE_ENV === "development") {
  mountStoreDevtool("ThesoStore", useThesoStore);
}

export default useThesoStore;
