import React from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";

import MyBreadcrumbs from "../breadcrumbs/breadcrumbs";
import MenuIcon from "@mui/icons-material/Menu";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";

import LightModeIcon from "@mui/icons-material/LightMode";
import NightlightIcon from "@mui/icons-material/Nightlight";

import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import useUiStore from "../../../stores/useUiStore";

import { styled } from "@mui/material/styles";

const MyAppBar = styled(AppBar)(() => ({
  pointerEvents: "none",
  // overflow: "hidden",
  // "& .MuiModal-backdrop": {
  //   backgroundColor: "rgba(0,0,0,0.0)",
  // },
}));

const Header = (props) => {
  const { handleOpen, handleToc } = props;

  const HeaderContent = () => {
    const navigate = useNavigate();

    const paramsTexte = useUiStore((state) => state.paramsTexte);

    const handleFontSize = (value) => {
      useUiStore.setState({
        paramsTexte: { ...paramsTexte, fontSize: value },
      });
    };

    const handleThemeMode = () => {
      useUiStore.setState({
        paramsTexte: {
          ...paramsTexte,
          mode: paramsTexte.mode === "light" ? "dark" : "light",
        },
      });
    };

    const fontSize = { max: 22, min: 15 };

    const handleHomeClick = () => {
      navigate("/");
    };
    return (
      <MyAppBar color="transparent" elevation={0}>
        <Toolbar variant="dense">
          <Box sx={{ flexGrow: 1 }}>
            <MyBreadcrumbs handleToc={handleToc} />
          </Box>

          <Box sx={{ flexGrow: 0, pointerEvents: "auto" }}>
            <Tooltip
              title={
                <Typography>
                  {paramsTexte.mode === "dark"
                    ? `Passer en mode clair`
                    : `Passer en mode sombre`}
                </Typography>
              }
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.black",
                    "& .MuiTooltip-arrow": {
                      color: "common.black",
                    },
                  },
                },
              }}
            >
              <IconButton onClick={() => handleThemeMode()}>
                {paramsTexte.mode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <Typography>
                  {paramsTexte.fontSize === fontSize.max
                    ? `Diminuer la taille du texte`
                    : `Augmenter la taille du texte`}
                </Typography>
              }
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.black",
                    "& .MuiTooltip-arrow": {
                      color: "common.black",
                    },
                  },
                },
              }}
            >
              {paramsTexte.fontSize === fontSize.max ? (
                <IconButton onClick={() => handleFontSize(fontSize.min)}>
                  <TextDecreaseIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => handleFontSize(fontSize.max)}>
                  <TextIncreaseIcon />
                </IconButton>
              )}
            </Tooltip>

            <Tooltip
              title={<Typography>{`Texte général`}</Typography>}
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.black",
                    "& .MuiTooltip-arrow": {
                      color: "common.black",
                    },
                  },
                },
              }}
            >
              <IconButton onClick={handleOpen}>
                <AutoStoriesOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </MyAppBar>
    );
  };

  return (
    <Box sx={{ position: "absolute", width: "600px" }}>
      <HeaderContent />
    </Box>
  );
};

export default Header;
