import React, { useState, useEffect } from "react";
import { Box, Fab, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useFullScreen } from "../../../hooks";

import TuneIcon from "@mui/icons-material/Tune";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

import CustomFab from "../../../ui/fabs/customFab";

const MyBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    zIndex: 1051,
  },
}));

const Fabs = (props) => {
  const { handleSettings, handleDrawer, fragmentsNumber } = props;
  const backgroundColor = "#FFFFFF66";

  // const [isFullScreen, setIsFullScreen] = useState();
  // useFullScreen(setIsFullScreen);

  const handleFullScreenChange = () => {
    Boolean(document.fullscreenElement)
      ? document.exitFullscreen()
      : document.body.requestFullscreen();
  };

  const fabStyle = { backgroundColor: backgroundColor };

  const fabs = [
    {
      title: "Réglages",
      icon: <TuneIcon />,
      onClick: handleSettings(true),
      style: fabStyle,
    },
    {
      title: "Recherche",
      icon: <ManageSearchIcon />,
      onClick: handleDrawer(true),
      style: fabStyle,
    },
    {
      title: "Aléatoire",
      icon: <AutorenewIcon />,
      disabled: true,
      style: fabStyle,
    },
    {
      title: Boolean(document.fullscreenElement)
        ? "Sortir du plein écran"
        : "Plein écran",
      style: fabStyle,
      icon: Boolean(document.fullscreenElement) ? (
        <FullscreenExitIcon />
      ) : (
        <FullscreenIcon />
      ),
      onClick: handleFullScreenChange,
    },
  ];

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }} id="fabs">
      {fabs.map(
        (fab, i) =>
          (fab.modeAdmin == null || fab.modeAdmin === config?.modeAdmin) && (
            <CustomFab key={i} {...fab}>
              {fab.icon}
            </CustomFab>
          )
      )}

      {/* <MyBadge badgeContent={fragmentsNumber} max={999} color="primary">
        <Fab
          size="small"
          title="Recherche"
          onClick={handleDrawer(true)}
          sx={{ backgroundColor: backgroundColor }}
        >
          <ManageSearchIcon />
        </Fab>
      </MyBadge> */}
    </Box>
  );
};

export default Fabs;
