import React from "react";
import useStyledXML from "./useStyledXML";

const useSections = (section) => {
  const fragOptions = {
    ignoreAttributes: false,
    attributeNamePrefix: "",
    trim: true,
    stopNodes: ["*.head", "*.label"],
    processEntities: true,
    ignoreDeclaration: true,
    ignorePiTags: true,
    removeNSPrefix: true,
    isArray: (name, jpath, isLeafNode, isAttribute) => {
      if (["children", "div", "p", "ref"].indexOf(name) !== -1) return true;
    },
  };

  const sections = useStyledXML({
    xml: "texteGeneral",
    xslt: "tableOfContent",
    expr: `$..body..[?(@.subtype)]`,
    parserOptions: fragOptions,
  });

  let res = section ? sections?.find((s) => s.subtype === section) : sections;

  return res;
};

export default useSections;
