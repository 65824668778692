import React from "react";
import { Chip, Stack, Tooltip, Typography, Box } from "@mui/material";

import { truncateSentence } from "../../../../outils";
import useUiStore from "../../../../stores/useUiStore";

const ListOccurences = ({ occurences }) => {
  const paramsTexte = useUiStore((state) => state.paramsTexte);

  const handleClick = (subtype) => {
    useUiStore.setState({
      paramsTexte: {
        ...paramsTexte,
        currentTexte: subtype,
        cardTexteOpen: true,
      },
    });
  };

  return (
    <>
      <div className="list-occurences">
        {occurences.length ? (
          <Box
            sx={{
              p: 1,
              borderRadius: "10px",
              border: "1px solid ",
              borderColor: "divider",
            }}
          >
            <Typography
              variant="body1"
              sx={{ pointerEvents: "none" }}
              component="div"
            >
              Apparaît dans le texte
            </Typography>
            <Stack>
              {occurences.map((occurence, index) => (
                <Tooltip
                  key={index}
                  title={<Typography>{occurence.head}</Typography>}
                  arrow
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "common.black",
                        "& .MuiTooltip-arrow": {
                          color: "common.black",
                        },
                      },
                    },
                  }}
                >
                  <Chip
                    // key={index}
                    label={truncateSentence(occurence.head, 4)}
                    variant="outlined"
                    sx={{ margin: "2px", borderColor: "divider" }}
                    onClick={() => handleClick(occurence.subtype)}
                    // title={occurence.head}
                    // size="small"
                  />
                </Tooltip>
              ))}
            </Stack>
          </Box>
        ) : null}
      </div>
    </>
  );
};

export default ListOccurences;
