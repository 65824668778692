import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  Stack,
  Button,
  Skeleton,
  Chip,
} from "@mui/material";

import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useThesoStore from "../../../../stores/useThesoStore";
import useDefinitionStore from "../../../../stores/useDefinitionStore";

import { getRelated } from "../../../../outils";

import ChipAnnotations from "./chipAnnotation";

const BulleAnnotation = (props) => {
  const { concept, couche, hex } = props;

  const [related, setRelated] = useState(null);
  const thesaurus = useThesoStore((state) => state.opentheso);

  const TitleLink = (props) => {
    const { concept } = props;
    console.log(couche, hex);

    return (
      <Link
        to={hex != "000000" && `/fragments/${couche}/${hex}`}
        // rel="noopener noreferrer"
      >
        <Typography variant="button">
          {concept?.prefLabel?.["#text"]}
        </Typography>
      </Link>
    );
  };

  useEffect(() => {
    if (concept.related) {
      let listRelated = getRelated(concept.related, thesaurus);
      setRelated(listRelated);
    }
  }, [thesaurus]);

  return (
    <Box sx={{ p: 1 }}>
      <TitleLink concept={concept} />
      <Divider />

      {concept?.definition && (
        <>
          <Stack sx={{ pt: 1 }}>
            <Typography variant="body2" paragraph align="justify">
              {concept?.definition?.["#text"]}
            </Typography>
          </Stack>
          <Divider />
        </>
      )}

      {related && related.length > 0 && (
        <>
          {/* <Stack
            sx={{ py: 1, pb: 1 }}
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-start"
            // useFlexGap
            flexWrap="wrap"
          > */}
          <Box sx={{ py: 0.5 }}>
            {related.map((item, index) => (
              <ChipAnnotations key={index} {...item} />
            ))}
          </Box>
          {/* </Stack> */}
          <Divider />
        </>
      )}

      <Stack sx={{ pt: 1 }} direction="row" alignItems="end">
        <Button
          size="small"
          startIcon={<OpenInNewIcon fontSize="small" />}
          target="_blank"
          href={props.source}
          title="Vers opentheso"
          // variant="text"
        >
          opentheso
        </Button>
      </Stack>
    </Box>
  );
};

export default BulleAnnotation;
