import React, { useState, useEffect } from "react";
import { Box, Fab, Badge, Tooltip, Typography } from "@mui/material";

import TuneIcon from "@mui/icons-material/Tune";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import RefreshIcon from "@mui/icons-material/Refresh";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationOffIcon from "@mui/icons-material/LocationOff";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocationOffOutlinedIcon from "@mui/icons-material/LocationOffOutlined";

import NotesIcon from "@mui/icons-material/Notes";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DescriptionIcon from "@mui/icons-material/Description";

import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";

import SquareFootIcon from "@mui/icons-material/SquareFoot";
import StraightenIcon from "@mui/icons-material/Straighten";

import useUiStore from "../../../../stores/useUiStore";
import { useFetch, useKeys } from "../../../hooks";

import CustomFab from "../../../ui/fabs/customFab";

const FragmentFabs = (props) => {
  const {
    handleSettings,
    handleFitToSphere,
    toogleAnnotations,
    setOpenNotice,
    openNotice,
    annotationsOn,
    setOpenGallery,
    openGallery,
    setOpenGalleryModal,
    openGalleryModal,
    hasAnnotations2D,
  } = props;

  const [triggerReframe, setTriggerReframe] = useState(false);
  const paramsFragment = useUiStore((state) => state.paramsFragment);

  const { data: config } = useFetch({ url: "/json/config.json" });

  const backgroundColor = "#FFFFFF66";

  const handleFullScreenChange = () => {
    Boolean(document.fullscreenElement)
      ? document.exitFullscreen()
      : document.body.requestFullscreen();
  };

  const handleReframe = () => {
    setTriggerReframe(!triggerReframe);
    useUiStore.setState(
      (state) => (state.paramsFragment.reframe = { value: triggerReframe })
    );
  };
  useKeys("n", () => setOpenNotice(!openNotice));

  const fabStyle = { backgroundColor: backgroundColor };

  const fabs = [
    {
      title: "Réglage",
      style: fabStyle,
      icon: <TuneIcon />,
      onClick: handleSettings(true),
    },
    {
      title: Boolean(document.fullscreenElement)
        ? "Sortir du plein écran"
        : "Plein écran",
      style: fabStyle,
      icon: Boolean(document.fullscreenElement) ? (
        <FullscreenExitIcon />
      ) : (
        <FullscreenIcon />
      ),
      onClick: handleFullScreenChange,
    },
    {
      title: "Recentrer",
      style: fabStyle,
      icon: <CenterFocusWeakIcon />,
      onClick: handleFitToSphere,
    },
    {
      title: "Notice",
      disabled: openGalleryModal || openGallery,
      style: { backgroundColor: openNotice ? "#FFFFFF" : backgroundColor },
      icon: <DescriptionOutlinedIcon />,
      onClick: () => setOpenNotice(!openNotice),
    },
    {
      title: "Galerie Volet",
      disabled: openNotice || openGalleryModal || !hasAnnotations2D,
      style: { backgroundColor: openGallery ? "#FFFFFF" : backgroundColor },
      icon: <CollectionsOutlinedIcon />,
      onClick: () => setOpenGallery(!openGallery),
    },
    {
      title: "Mesure",
      disabled: true,
      style: { backgroundColor: openGallery ? "#FFFFFF" : backgroundColor },
      icon: <StraightenIcon />,
      onClick: () => console.log("mesure"),
    },
    {
      title: "Annotations",
      style: fabStyle,
      icon: annotationsOn ? (
        <LocationOnOutlinedIcon />
      ) : (
        <LocationOffOutlinedIcon />
      ),
      onClick: toogleAnnotations,
      modeAdmin: true,
    },
  ];

  // const CustomFab = ({ title, style, onClick, children, disabled }) => {
  //   return (
  //     <Tooltip
  //       title={<Typography>{title}</Typography>}
  //       arrow
  //       componentsProps={{
  //         tooltip: {
  //           sx: {
  //             bgcolor: "common.black",
  //             "& .MuiTooltip-arrow": {
  //               color: "common.black",
  //             },
  //           },
  //         },
  //       }}
  //     >
  //       <Box display="inline-flex">
  //         <Fab
  //           size="small"
  //           onClick={onClick}
  //           sx={style}
  //           disabled={false || disabled}
  //         >
  //           {children}
  //         </Fab>
  //       </Box>
  //     </Tooltip>
  //   );
  // };

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }} id="fabs">
      {fabs.map(
        (fab, i) =>
          (fab.modeAdmin == null || fab.modeAdmin === config?.modeAdmin) && (
            <CustomFab key={i} {...fab}>
              {fab.icon}
            </CustomFab>
          )
      )}
    </Box>
  );
};

export default FragmentFabs;
