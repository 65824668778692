import React, { useState, useEffect } from "react";

import { useHistoryBack, useKeys } from "../hooks";

import useUiStore from "../../stores/useUiStore";

import Header from "./header/header";
import ModalTocText from "./modalTocText/modalTocTex";
import DrawerToc from "./drawerToc/drawerToc";
import DrawerTocText from "./drawerTocText/drawerTocText";
import CardText from "./cardText/cardText";
import CardTocWrapper from "./cardToc/cardTocWrapper";
import Plan from "../plan/plan";

const UI = () => {
  const [open, setOpen] = useState(false);
  const [openToc, setOpenToc] = useState(false);

  const paramsTexte = useUiStore((state) => state.paramsTexte);
  const handleOpen = () => {
    // setOpen(true);
    useUiStore.setState({
      paramsTexte: { ...paramsTexte, cardTexteOpen: true },
    });
  };
  const handleClose = () => {
    setOpen(false);
    // useUiStore.setState({
    //   paramsTexte: { ...paramsTexte, drawerTexteOpen: false },
    // });
  };
  const handleToc = (bool) => {
    setOpenToc(bool);
    // useUiStore.setState({
    //   paramsTexte: { ...paramsTexte, drawerTocOpen: true },
    // });
  };
  const back = useHistoryBack();

  useKeys("t", () =>
    useUiStore.setState({
      paramsTexte: {
        ...paramsTexte,
        cardTexteOpen: !paramsTexte.cardTexteOpen,
      },
    })
  );

  useKeys("m", () => setOpenToc(!openToc));

  return (
    <>
      <Plan />
      <Header handleOpen={handleOpen} handleToc={handleToc} />
      {/* <ModalTocText open={open} handleClose={handleClose} /> */}
      {/* <DrawerToc open={open} handleClose={handleClose} /> */}
      {/* <DrawerTocText open={open} handleClose={handleClose} /> */}
      <CardText open={open} handleClose={handleClose} />
      <CardTocWrapper open={openToc} handleClose={() => handleToc(false)} />
    </>
  );
};

export default UI;
