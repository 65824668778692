import React from "react";
import { Route, Router } from "wouter";
import { useHashLocation } from "../../../hooks";

const RouteScene = (props) => {
  const { path, ...others } = props;

  return (
    <Route path={path} nest>
      {others.children}
    </Route>
  );
};

const env = import.meta.env.VITE_NODE_ENV;

const RouteProd = (props) => {
  return (
    <Router hook={useHashLocation}>
      <RouteScene {...props} />
    </Router>
  );
};

const RouteDev = (props) => {
  return (
    <Router>
      <RouteScene {...props} />
    </Router>
  );
};

const Routes = {
  development: RouteDev,
  production: RouteProd,
};

const CurrentWoute = (props) => {
  let MyRoute = Routes[env];
  return <MyRoute {...props} />;
};

export default CurrentWoute;
