import React, { Suspense, useState, useRef, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { Text, useCursor, Html } from "@react-three/drei";
import { checkIfUrlExists, truncateString } from "../../../outils";
import useUiStore from "../../../stores/useUiStore";
import { useTheme } from "@mui/material/styles";

import "./fragments.css";

import ModelPreview from "./modelPreview";

const Cell = (props) => {
  const {
    position,
    name,
    filename,
    preview,
    cellSize,
    sujet,
    rotationFragment,
    filter,
  } = props;

  const paramsFragments = useUiStore((state) => state.paramsFragments);

  const [hovered, setHovered] = useState(false);

  const [isPreviewModel, setIsPreviewModel] = useState();

  const { palette } = useTheme();

  //checkIfUrlExists(`/modeles/${filename}/5K/${filename}.glb`)

  // const isPreviewModel = checkIfUrlExists(
  //   `/modeles/${filename}/5K/${filename}.glb`
  // );

  // const isPreviewModel = useMemo(() => {
  //   return checkIfUrlExists(`/modeles/${filename}/5K/${filename}.glb`);
  // }, []);

  useEffect(() => {
    setIsPreviewModel(
      checkIfUrlExists(`/modeles/${filename}/5K/${filename}.glb`)
    );
  }, []);

  const navigate = useNavigate();

  const white = new THREE.Color("white");
  const goldenrod = new THREE.Color("goldenrod");
  const vecPositionInit = new THREE.Vector3();
  const vecPositionHovered = new THREE.Vector3();

  const groupRef = useRef();
  const matRef = useRef();
  const meshRef = useRef();
  const textRef = useRef();

  const icoMeshRef = useRef();
  const icoMatRef = useRef();

  const octaMeshRef = useRef();
  const octaMatRef = useRef();

  const Octahedron = () => {
    return (
      <mesh name={`octahedron`} visible={true}>
        <octahedronGeometry args={[0.15, 1]} />
        <meshBasicMaterial wireframe transparent opacity={0.25} />
      </mesh>
    );
  };

  useCursor(hovered);

  const handleClick = () => {
    navigate(`/fragments/${name}`);
  };

  const handleMouseOver = (e) => {
    e.stopPropagation(), setHovered(true);
  };
  const handleMouseOut = (e) => {
    e.stopPropagation(), setHovered(false);
  };

  useFrame((state, delta) => {
    vecPositionInit.set(
      groupRef.current.position.x,
      groupRef.current.position.y,
      0
    );
    vecPositionHovered.set(
      groupRef.current.position.x,
      groupRef.current.position.y,
      cellSize * 2
    );

    // isPreviewModel &&
    //   matRef.current.color.lerp(hovered ? goldenrod : white, 0.3);
    textRef.current.color.lerp(hovered ? goldenrod : white, 0.3);

    // paramsFragments?.animated?.value
    //   ? (meshRef.current.rotation.z =
    //       meshRef.current.rotation.x =
    //       meshRef.current.rotation.y +=
    //         delta / 12)
    //   : null;
  });

  return (
    <group
      ref={groupRef}
      name={`${filename}_group`}
      position={position}
      onClick={handleClick}
      dispose={true}
    >
      <mesh name={`${filename}_box`} visible={false}>
        <boxGeometry args={[cellSize, cellSize, cellSize]} />
        <meshBasicMaterial wireframe={true} transparent opacity={1} />
      </mesh>
      <mesh
        name={`${filename}_plane`}
        visible={false}
        position={[0, -cellSize * 0.1, 0]}
        onPointerOver={handleMouseOver}
        onPointerOut={handleMouseOut}
      >
        <planeGeometry args={[cellSize, cellSize * 1.1]} />
        <meshBasicMaterial wireframe transparent opacity={1} />
      </mesh>
      {/* <mesh ref={icoMeshRef} name={`${filename}_icosahedron`} visible={false}>
        <icosahedronGeometry args={[0.15]} />
        <meshBasicMaterial wireframe ref={icoMatRef} />
      </mesh> */}

      {preview ? (
        //&& isPreviewModel

        //<Suspense fallback={null}>
        <ModelPreview
          filename={filename}
          hovered={hovered}
          cellSize={cellSize}
          rotationFragment={rotationFragment}
        />
      ) : (
        //</Suspense>
        <mesh ref={octaMeshRef} name={`${filename}_octahedron`} visible={true}>
          <octahedronGeometry args={[0.15, 1]} />
          <meshBasicMaterial
            wireframe
            ref={octaMatRef}
            transparent
            opacity={0.25}
          />
        </mesh>
      )}

      <Text
        font={"/fonts/Roboto_Condensed/static/RobotoCondensed-Regular.ttf"}
        visible={paramsFragments?.numero?.value}
        position={[0, (-cellSize * 1.1) / 2, 0]}
        anchorX="center"
        anchorY="top"
        fontSize={0.03 * cellSize * 3}
        name={`${filename}_text`}
        color="text.primary"
        // color={hovered ? "goldenrod" : "white"}
      >
        {name}
        <meshBasicMaterial ref={textRef} />
      </Text>
      <Html
        as="div"
        name={`${filename}_popup`}
        className="fragments-tooltip"
        zIndexRange={[250, 100]}
        style={{
          color: palette.text.primary,
          backgroundColor: palette.background.world,
          opacity: hovered ? 1 : 0,
          visibility: paramsFragments?.legend?.value
            ? hovered
              ? "visible"
              : "hidden"
            : "hidden",
        }}
      >
        {sujet && truncateString(sujet, 56)}
      </Html>
    </group>
  );
};

export default Cell;
