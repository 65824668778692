import React from "react";
import * as THREE from "three";
import { Html } from "@react-three/drei";
import { useTheme } from "@mui/material/styles";

const Label = (props) => {
  const { name } = props;
  const { palette } = useTheme();

  function calculatePosition(el, camera, size) {
    let obj = el.parent.children[0];

    const center = new THREE.Vector3();

    const box = new THREE.Box3().setFromObject(obj);
    const objectPos = box.getCenter(center);
    objectPos.project(camera);

    const widthHalf = size.width / 2;
    const heightHalf = size.height / 2;

    // console.log(objectPos.x * widthHalf);

    return [
      Math.min(
        size.width - 100,
        Math.max(0, objectPos.x * widthHalf + widthHalf)
      ),
      Math.min(
        size.height - 50,
        // Math.max(0, -(objectPos.y * heightHalf) + heightHalf)
        Math.max(0, -(objectPos.y * heightHalf) + heightHalf - 25)
      ),
    ];
  }

  const handleClick = () => {
    console.log("clicked");
  };

  return (
    <Html
      as="div"
      calculatePosition={calculatePosition}
      center
      style={{
        backgroundColor: palette.background.world,
      }}
      className="monument-annotation"
    >
      <div>{props?.title || name}</div>
    </Html>
  );
};

export default Label;
