import React, { useMemo, forwardRef, useState } from "react";
import * as THREE from "three";
import { Icosahedron, Circle, useCursor } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRoute } from "wouter";
import Fragment from "./fragment";

import useModelStore from "../../../../stores/useModelStore";
import useUiStore from "../../../../stores/useUiStore";

import { shuffle } from "../../../../outils";

const FragmentHelix = forwardRef((props, ref) => {
  const { handleClick, handleLocalClick, handleContextMenu, name } = props;

  const [hovered, setHovered] = useState(null);
  const [match, params] = useRoute(`/?/fragments`);

  const fragments = useModelStore((state) => state.fragments)
    .filter((f) => f.gltf_5k === true && f.gltf_tholos === false)
    .map((f) => f.numero_inventaire_3d);

  const paramsTholos = useUiStore((state) => state.paramsTholos);
  const random = useUiStore((state) => state.random);

  let shuffledList = useMemo(
    () =>
      random
        ? shuffle(fragments.map((item, index) => index)).map(
            (s) => fragments[s]
          )
        : fragments,
    [random]
  );

  hovered
    ? (document.body.style.cursor = "pointer")
    : (document.body.style.cursor = "default");

  const handlePointerOver = (e) => {
    setHovered(true);
  };

  const handlePointerOut = (e) => {
    setHovered(false);
  };

  useFrame((state, delta) => {
    !match && !hovered && (ref.current.rotation.y += delta * 0.005);
  });

  return (
    <group
      ref={ref}
      name={name}
      visible={paramsTholos.decor.value}
      onClick={handleClick}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
      onContextMenu={handleContextMenu}
      userData={{ collection: "fragments" }}
    >
      {shuffledList.map((fragment, index) => (
        <group
          key={index}
          name={`fragment_${index}`}
          rotation={[0, Math.PI / 20 + (Math.PI / 20) * index, 0]}
          position={[0, 6.5, 0]}
          // onClick={handleLocalClick}
        >
          <Fragment
            index={index}
            name={fragment}
            hovered={hovered}
            collection="fragments"
            position={[-9.5, index * 0.03, 0]}
            rotation={[0, -Math.PI / 2, 0]}
            handleLocalClick={handleLocalClick}
          />
        </group>
      ))}
    </group>
  );
});

export default FragmentHelix;
