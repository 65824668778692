import React, { useEffect, useState } from "react";
import { XMLParser } from "fast-xml-parser";

const parserOptions = {
  trim: true,
};
const parser = new XMLParser();

const useGetOpentheso = (params) => {
  const [theso, setTheso] = useState();
  const [loading, setLoading] = useState(false);

  const { idc, idt } = params;

  let path = "https://opentheso.huma-num.fr/opentheso/api/";

  useEffect(() => {
    setLoading(() => true);

    try {
      fetch(`${path}${idt}.${idc}`)
        .then((res) => res.text())
        .then((text) => parser.parse(text, parserOptions))
        .then((data) => data[`rdf:RDF`][`rdf:Description`])
        .then((returnData) =>
          setTheso({
            definition: returnData[`skos:definition`],
            label: returnData[`skos:preflabel`],
            notation: returnData[`skos:notation`],
          })
        )
        .finally(setLoading(() => false));
    } catch (e) {
      console.log(e);
    }
  }, []);

  return {
    ...params,
    ...theso,
  };
};

export default useGetOpentheso;
