import React, { useRef, forwardRef, useState } from "react";
import { useGLTF, useTexture, Text, useCursor } from "@react-three/drei";
import { useLocation, useRoute } from "wouter";
import { useTheme } from "@mui/material/styles";

import Fragment from "./fragment";
import Complement from "./complement";
import Reconstitution from "./reconstitution";

import useKeys from "../../../hooks/useKeys";
import { normalizeString, fetchUrl } from "../../../../outils";
import { HourglassFullOutlined } from "@mui/icons-material";

const Composition = forwardRef((props, ref) => {
  const {
    position,
    fragments,
    handleClick,
    positionSupport,
    filename,
    anchorText,
  } = props;

  // console.log("composition", props);

  const { nodes, materials } = useGLTF(`/modeles/tholos/${filename}.glb`);
  const { palette } = useTheme();

  const [hovered, setHovered] = useState(false);

  const [, setLocation] = useLocation();
  const [, params] = useRoute();

  const matRef = useRef();
  const meshRef = useRef();

  const fragmentRef = useRef();
  const fragmentRefs = Array.from(
    { length: fragments?.fragments?.length },
    () => React.createRef(null)
  );

  const name = fragments?.title || fragments.name;
  const uri = normalizeString(name);

  const active = params[`*`] === uri;

  const isAcrotere = filename === "acrotere";

  const texture = useTexture({ map: `/modeles/tholos/acrotere.png` });
  texture.map.flipY = false;

  useKeys("ArrowUp", () => setLocation("/"));
  useCursor(hovered);

  const handleMouseOver = () => {
    setHovered(true);
  };
  const handleMouseOut = () => {
    setHovered(false);
  };

  /**
   * Prechargement des reconstitutions
   */

  fragments?.reconstitution?.status &&
    useGLTF.preload(
      `/modeles/metopes/${fragments?.decor_ID}/${fragments?.decor_ID}_reconstitution.glb`
    );

  return (
    <group
      ref={ref}
      {...props}
      dispose={null}
      position={position}
      name={name}
      userData={{ uri: uri }}
      onPointerOver={handleMouseOver}
      onPointerOut={handleMouseOut}
    >
      <mesh
        ref={meshRef}
        onClick={handleClick}
        name={`plaque`}
        transparent
        opacity={1}
        castShadow
        receiveShadow
        geometry={nodes[`${filename}`].geometry}
        position={[0, 0, 0]}
        visible={fragments?.complementPlaque ? false : true}
      >
        {!isAcrotere ? (
          <meshStandardMaterial
            name={`mat`}
            transparent={true}
            opacity={0.75}
          />
        ) : (
          <meshStandardMaterial
            name={`mat`}
            {...texture}
            opacity={0.25}
            transparent={true}
          />
        )}
      </mesh>

      <group position={positionSupport} name="fragments">
        {fragments?.name && fragments?.fragments?.length < 1 && (
          <Fragment ref={fragmentRef} {...fragments} active={active} />
        )}
        {fragments?.name &&
          fragments?.fragments?.length > 1 &&
          fragments?.fragments?.map((fragment, i) => (
            <Fragment
              ref={fragmentRefs[i]}
              key={i}
              {...fragment}
              active={active}
            />
          ))}
        {fragments?.complementPlaque && <Complement {...fragments} />}
        {active && fragments?.reconstitution?.status && (
          <Reconstitution {...fragments} positionSupport={positionSupport} />
        )}
      </group>
      {name.includes("composition-") ? null : (
        <Text
          font={"/fonts/Roboto_Condensed/static/RobotoCondensed-Regular.ttf"}
          fontSize={0.06}
          anchorY="middle"
          position={anchorText}
          color={
            hovered && !active
              ? palette.text.primary
              : active
              ? "orange"
              : palette.text.disabled
          }
          overflowWrap="break-word"
          whiteSpace="normal"
          maxWidth={0.6}
          textAlign="center"
          onClick={handleClick}
        >
          {name}
        </Text>
      )}
    </group>
  );
});

export default Composition;
