import React, { useState, useEffect } from "react";
import { Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocation as useWocation, useRoute as useWoute } from "wouter";
import useHashLocation from "../hooks/useHashLocation";
import { X } from "@mui/icons-material";
import { useColors } from "../hooks";

const Collection = (props) => {
  const { translation, positions, size, shift, id, title, radius } = props;
  const [hovered, setHovered] = useState(false);
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { palette } = useTheme();

  const { base } = useColors();

  const fill = hovered
    ? base.fill.hovered
    : selected
    ? base.fill.selected
    : base.fill.idle;
  const stroke = hovered
    ? base.stroke.hovered
    : selected
    ? base.stroke.selected
    : base.stroke.idle;

  const handleClick = () => {
    navigate(`/${id}`);
  };
  const handleMouseOver = (e) => {
    e.stopPropagation();
    setHovered(true);
  };
  const handleMouseOut = (e) => {
    e.stopPropagation();
    setHovered(false);
  };

  useEffect(() => {
    if (location.pathname === `/${id}`) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [location]);

  // let maxX = Math.max(...positions.map((p) => p.x))

  let [maxX, maxY] = ["x", "y"].map((item, index) =>
    Math.max(...positions.map((p) => p[item] + 1))
  );

  const titleLowerCase = title.toLowerCase();

  const toCollection =
    titleLowerCase === "acrotères"
      ? `d'${titleLowerCase}`
      : `de ${titleLowerCase}`;

  return (
    <Tooltip
      title={<Typography>{`vers la collection ${toCollection}`}</Typography>}
      arrow
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.black",
            "& .MuiTooltip-arrow": {
              color: "common.black",
            },
          },
        },
      }}
    >
      <g
        id={id}
        transform={`translate(${translation.x} ${translation.y})`}
        className="fragments"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
      >
        <rect
          id="fond"
          // x={0}
          // y={0}
          x={-1.5}
          y={-1.5}
          // height={2.5 * shift.height}
          // width={2.5 * shift.width}
          height={maxY * shift.height}
          width={maxX * shift.width}
          fill="#ffffff00"
          stroke="none"
          rx={radius}
        />
        <g id="elements" fill={fill} stroke={stroke} className="fragments">
          {positions.map((r, i) => (
            <g key={i}>
              <rect
                x={r.x * shift.height}
                y={r.y * shift.width}
                height={size.height}
                width={size.width}
                id={`rect-${i}`}
                rx={radius}
              />
              {titleLowerCase.includes("métope") && (
                <rect
                  x={r.x * shift.height}
                  y={r.y * shift.width}
                  height={size.height / 3}
                  width={size.width}
                  id={`rect-${i}`}
                  rx={radius}
                />
              )}
            </g>
          ))}
        </g>
        {/* <title>{`vers la collection ${toCollection}`}</title> */}
      </g>
    </Tooltip>
  );
};

export default Collection;
