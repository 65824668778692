import React, { useState, forwardRef } from "react";
import Metope from "./metope";
import Label from "../ui/label";

const Metopes = forwardRef((props, ref) => {
  const { handleClick, handleContextMenu, name, dimension, ...others } = props;
  const [hovered, setHovered] = useState(null);

  const handlePointerOver = (e) => {
    setHovered(true);
  };

  const handlePointerOut = (e) => {
    setHovered(false);
  };

  hovered
    ? (document.body.style.cursor = "pointer")
    : (document.body.style.cursor = "default");

  return (
    <group
      ref={ref}
      name={name}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
      onContextMenu={handleContextMenu}
      onClick={handleClick}
      userData={{ collection: name }}
    >
      {[...Array(40)].map((item, index) => (
        <Metope
          key={index}
          name={`${dimension}-metope_${index + 1}`}
          collection={name}
          rotation={[0, (Math.PI / 20) * (index + 1), 0]}
          hovered={hovered}
          url={`/modeles/tholos/${dimension}_metope.glb`}
          {...others}
        />
      ))}
    </group>
  );
});

export default Metopes;
