import React, { useState, useRef, useEffect, useMemo } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { CameraControls, Sphere } from "@react-three/drei";
import useModelStore from "../../../stores/useModelStore";

import {
  useParams,
  useNavigate,
  useMatch,
  useLocation,
} from "react-router-dom";
import { useRoute, Route } from "wouter";

import Frame from "./frame";

const Rig = () => {
  const { pathname } = useLocation();

  const currentSelection = useModelStore((state) => state.currentSelection);
  const currentFragments = useModelStore((state) => state.fragments)
    .filter((f) => f.gltf === true)
    .filter((f) => currentSelection?.includes(f.numero_inventaire_3d));

  const cameraControlsRef = useRef();

  const frameRefs = Array.from({ length: currentFragments?.length }, () =>
    React.createRef(null)
  );

  const group = useRef();
  const sphere = useRef();

  const { viewport, pointer } = useThree();

  const padding = {
    paddingTop: 0.2,
    paddingLeft: 0.1,
    paddingBottom: 0.2,
    paddingRight: 0.1,
  };

  const onPointerMissed = (e) => {
    e.stopPropagation();
    cameraControlsRef.current.fitToBox(group.current, true, padding);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    cameraControlsRef.current.fitToBox(e.eventObject, true, padding);
  };

  function getDiametre(obj) {
    let bbox = new THREE.Box3().setFromObject(obj);
    let bsphere = bbox.getBoundingSphere(new THREE.Sphere());
    return bsphere.radius * 2;
  }

  useEffect(() => {
    if (currentFragments?.length > 0) {
      let diametres = frameRefs.map((ref, i) => getDiametre(ref.current));
      let positions = [];
      let accumulated = diametres.reduce((acc, curr) => {
        positions.push(acc + curr / 2);
        return acc + curr;
      }, diametres[0] / 2);
      positions.map((p, i) => (frameRefs[i].current.position.x = p));
    }
  }, []);

  useEffect(() => {
    cameraControlsRef.current?.fitToBox(group.current, true, padding);
  }, [viewport.aspect, pathname]);

  return (
    currentSelection &&
    currentFragments?.length > 0 && (
      <>
        <group ref={group} onPointerMissed={onPointerMissed}>
          {currentFragments &&
            currentFragments.map((f, i) => (
              <Frame
                key={i}
                ref={frameRefs[i]}
                index={i}
                handleClick={handleClick}
                length={currentFragments.length}
                filename={f.numero_inventaire_3d}
                cellSize={0.75}
                rotationFragment={f.rotation_xyz}
              />
            ))}
        </group>

        <CameraControls ref={cameraControlsRef} />
      </>
    )
  );
};

export default Rig;
