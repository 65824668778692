import parseExternalUrl from "./parseExternalUrl";
import convertNotation from "./convertNotation";

function getRelated(related, thesaurus) {
  let urlList = Array.isArray(related)
    ? related.map((r) => r[`@_resource`])
    : [related[`@_resource`]];

  let idList = urlList.map((i) => {
    return parseInt(parseExternalUrl(i).idc);
  });

  // console.log(thesaurus);

  let conceptList = thesaurus
    .filter((c) => idList.includes(c.identifier[`#text`]))
    //.filter((c) => c.notation)
    .map((c) => {
      let notation = convertNotation(
        c.notation ? c.notation[`#text`] : "#00000000"
      );
      return {
        ...notation,
        label: c.prefLabel[`#text`],
        id: c.identifier[`#text`],
        url: c[`@_about`],
      };
    });

  return conceptList;
}
export default getRelated;
