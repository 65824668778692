import { useState, useEffect } from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes";
import { CssBaseline } from "@mui/material";
import UI from "./components/ui/ui";
import useUiStore from "./stores/useUiStore";

import { Leva } from "leva";
import Views from "./views";
import Queries from "./queries";

function App() {
  const paramsTexte = useUiStore((state) => state.paramsTexte);

  const darkColor = "#525252";
  const lightColor = "#897f77";

  const myTheme = createTheme({
    typography: {
      fontFamily: ["Roboto Condensed", "Open Sans"].join(","),

      fontSize: paramsTexte.fontSize,
    },
    palette: {
      mode: paramsTexte.mode,

      ...(paramsTexte.mode === "light"
        ? {
            background: { default: lightColor, world: "#ffffff99" },
          }
        : { background: { default: darkColor, world: "#00000099" } }),
    },
  });

  const [theme, setTheme] = useState(myTheme);

  useEffect(() => {
    setTheme(myTheme);
  }, [paramsTexte.fontSize, paramsTexte.mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="leva">
        <Leva fill />
      </div>
      <Views />
      <Queries />
      <UI />
    </ThemeProvider>
  );
}
export default App;
