import React from "react";
import { Canvas } from "@react-three/fiber";

const PseudoCanvas = (props) => {
  const width = props.width;
  return (
    <div
      className="webgl"
      id="scene3D-fragment"
      style={{ width: `calc(100% - ${width})` }}
    >
      <Canvas
        flat
        legacy={true}
        linear={true}
        shadows
        dpr={[1, 2]}
        camera={{
          fov: 20,
          near: 0.01,
          far: 40,
          position: [0, 0, 0.33],
        }}
      >
        {props.children}
      </Canvas>
    </div>
  );
};

export default PseudoCanvas;
