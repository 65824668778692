import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Box,
  Button,
  IconButton,
  ClickAwayListener,
  Typography,
  Link,
  Snackbar,
  Alert,
  Stack,
  Divider,
  Icon,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import HubIcon from "@mui/icons-material/Hub";
import ControlCameraOutlinedIcon from "@mui/icons-material/ControlCameraOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";

import useDefinitionStore from "../../../../stores/useDefinitionStore";
import useThesoStore from "../../../../stores/useThesoStore";
import { useFetch, useParseThesaurus } from "../../../hooks";

import { getRelated } from "../../../../outils";

import ChipAnnotation from "../../notices/ui/chipAnnotation";
import { list } from "../../../ui";

const FragmentAnnotationCard = (props) => {
  const { openNotice, width } = props;

  const [open, setOpen] = useState(false);
  const [related, setRelated] = useState();

  const navigate = useNavigate();

  const {
    tooltip,
    setTooltip,
    setMouseActive,
    setActiveColor,
    currentCouche,
    activeColor,
  } = useDefinitionStore((state) => ({
    tooltip: state.tooltip,
    setTooltip: state.setTooltip,
    setMouseActive: state.setMouseActive,
    setActiveColor: state.setActiveColor,
    currentCouche: state.currentCouche,
    activeColor: state.activeColor,
  }));

  const { data: config } = useFetch({ url: "/json/config.json" });

  const thesaurii = {
    iconographie: { id: 1, suffixe: "00", label: "Iconographie" },
    materialite: { id: 2, suffixe: "ff", label: "Materialité" },
  };

  const currentNotation = `#${tooltip.hex}${thesaurii[currentCouche].suffixe}`;

  const { thesaurus, isSuccess } = useParseThesaurus(
    thesaurii[currentCouche].id
  );

  const concept = useMemo(
    () =>
      thesaurus
        ?.filter((th) => th.notation)
        .find((th) => th.notation[`#text`] === currentNotation),
    [currentCouche, tooltip.hex, isSuccess]
  );

  const offset = -5;

  /*
   * HANDLERS
   */

  const handleClickAway = () => {
    setTooltip(-500, -500, false);
  };

  const handlePointerEnter = (e) => {
    setMouseActive(true);
    setActiveColor(tooltip.hex, currentCouche);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setTooltip(-500, -500, false);
    }, 250);
  };

  const handleClick = () => {
    navigate(`/fragments/${currentCouche}/${tooltip.hex}`);
  };

  const handleCopy = (e, item) => {
    navigator.clipboard.writeText(JSON.stringify(item));
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  /**
   *
   *  HOOKS
   */

  const handleRelated = useCallback((r, t) => {
    const listRelated = getRelated(r, t);
    setRelated(listRelated);
  }, []);

  useEffect(() => {
    if (concept?.related) {
      handleRelated(concept.related, thesaurus);
    }
  }, [concept, currentNotation, thesaurus]);

  // const getMyRelated = useCallback(getRelated(concept.related, thesaurus), [
  //   concept.related,
  //   thesaurus,
  // ]);

  // console.log("getMyRelated", getMyRelated);

  // useEffect(() => {
  //   if (concept?.related) {
  //     // let listRelated = getRelated(concept.related, thesaurus);
  //     console.log(getMyRelated);

  //     // setRelated(myRelated);
  //     // console.log("concept", listRelated, concept.related, currentNotation);
  //   }
  // }, [concept, currentNotation]);

  return (
    isSuccess &&
    concept && (
      <div
        id="annotation-space"
        style={{
          zIndex: 2500,
          position: "absolute",
          width: openNotice ? `calc(100% - ${width}px)` : `100%`,
          pointerEvents: "none",
        }}
      >
        <div
          id="annotation-card"
          onMouseLeave={handleMouseLeave}
          onMouseOver={handlePointerEnter}
          style={{
            position: "relative",
            maxWidth: "200px",
            pointerEvents: "auto",
            cursor: "pointer",
            top: `calc(${tooltip.y}px + ${offset}px)`,
            left: `calc(${tooltip.x}px + ${offset}px)`,
            visibility: tooltip.active ? "visible" : "hidden",
          }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Card
              sx={{ backgroundColor: "background.world", borderRadius: 2 }}
              elevation={0}
            >
              <CardHeader
                avatar={
                  <Avatar>
                    <Tooltip
                      title={
                        concept?.definition &&
                        concept.definition[`#text`] != undefined
                          ? concept.definition[`#text`]
                          : null
                      }
                      arrow
                    >
                      <IconButton onClick={handleClick}>
                        <RoomOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Avatar>
                }
                title={concept?.prefLabel[`#text`] || tooltip.hex}
                subheader={thesaurii[currentCouche]?.label}
              />
              <Divider />
              <CardContent>
                <Stack
                  direction="column"
                  spacing={0.5}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Button
                    startIcon={<HubIcon fontSize="small" />}
                    size="small"
                    title="autres fragments portant cette annotation"
                    onClick={handleClick}
                  >
                    Autres Fragments
                  </Button>
                  <Button
                    startIcon={<OpenInNewIcon fontSize="small" />}
                    size="small"
                    title="Vers la définition dans le thésaurus Opentheso"
                    target="_blank"
                    href={`${concept?.[`@_about`]}`}
                  >
                    Vers Opentheso
                  </Button>
                </Stack>
                {concept.related && (
                  <>
                    <Typography variant="caption">Termes associés</Typography>
                    <Box sx={{ py: 0.5 }}>
                      {related?.map((item, index) => (
                        <ChipAnnotation key={index} {...item} />
                      ))}
                    </Box>
                    {/* <Divider /> */}
                  </>
                )}
              </CardContent>
              <Divider />
              {config?.modeAdmin && (
                <CardActions>
                  {tooltip.annotation ? (
                    <Button
                      size="small"
                      startIcon={<ControlCameraOutlinedIcon />}
                      onClick={(e) => handleCopy(e, tooltip.annotation)}
                    >
                      Copier position
                    </Button>
                  ) : null}
                </CardActions>
              )}
            </Card>
          </ClickAwayListener>
        </div>
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="info">
            Position copiée. Faites CTRL+V pour coller dans votre document.
          </Alert>
        </Snackbar>
      </div>
    )
  );
};

export default FragmentAnnotationCard;
