import React from "react";
import * as THREE from "three";
import { useRoute, useLocation, Route } from "wouter";
import { Canvas, useThree } from "@react-three/fiber";
import { Typography } from "@mui/material";

import useKeys from "../../hooks/useKeys";

import CurrentRoute from "./common/currentRoute";

import Lights from "./lights";
import Collection from "./collection";

import { fragmentsGM, fragmentsPM, fragmentsAcro } from "../tholos/data";

const Collections = (props) => {
  const { name } = props;

  const [, setLocation] = useLocation();

  const collections = {
    "grandes-metopes": {
      name: "grandes-metopes",
      title: "Grandes métopes",
      filename: "grande_metope_plaque",
      fragments: fragmentsGM,
      position: [0, -0.3, 0],
      positionSupport: [0, 0.32, 0.05],
      positions: [
        [-1, 1, 0],
        [0, 1, 0],
        [1, 1, 0],
        [-1, 0, 0],
        [0, 0, 0],
        [1, 0, 0],
        [-1, -1, 0],
        [0, -1, 0],
        [1, -1, 0],
      ],
      anchorText: [0, -0.15, 0],
    },
    "petites-metopes": {
      name: "petites-metopes",
      title: "Petites métopes",
      filename: "petite_metope_plaque",
      fragments: fragmentsPM,
      position: [0, 0, 0],
      positionSupport: [0, 0, 0],
      positions: [
        [-0.75, 0.75, 0],
        [0, 0.75, 0],
        [0.75, 0.75, 0],
        [-0.75, 0, 0],
        [0, 0, 0],
        [0.75, 0, 0],
        [-0.75, -0.75, 0],
        [0, -0.75, 0],
        [0.75, -0.75, 0],
      ],
      anchorText: [0, -0.32, 0],
    },
    acroteres: {
      name: "acroteres",
      title: "Acrotères",
      // filename: "nike_wf",
      filename: "acrotere",
      fragments: fragmentsAcro,
      position: [0, 0, 0],
      positionSupport: [0, 0, 0.2],
      positions: [
        [0, 0, 0],
        [1, 0, 0],
        [-1, 0, 0],
      ],
      anchorText: [0, -0.8, 0],
    },
  };

  const currentCollection = collections[`${name}`];

  useKeys("Escape", () => {
    setLocation(`/${name}`);
  });

  //c

  return (
    <>
      <div className="sur-titre">Ensembles sculptés</div>
      <div className="titre">{currentCollection.title}</div>
      <div className="webgl" id={`scene3D-${name}`} style={{ width: "100%" }}>
        <Canvas
          flat
          legacy={true}
          linear={true}
          shadows
          dpr={[1, 2]}
          camera={{
            fov: 12,
            near: 0.01,
            far: 30,
            position: [0, 0, 15],
          }}
        >
          <CurrentRoute name={name} path={`/${name}`}>
            <Lights />
            <Collection {...currentCollection} />
          </CurrentRoute>
        </Canvas>
      </div>
    </>
  );
};
export default Collections;
