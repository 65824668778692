import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Text } from "@react-three/drei";

const FragmentDummy = (props) => {
  const { filename } = props;
  const octaMeshRef = useRef();

  useFrame((state, delta) => {
    octaMeshRef.current.rotation.y += delta * 0.02;
    octaMeshRef.current.rotation.x += delta * 0.05;
  });

  return (
    <group>
      <mesh
        ref={octaMeshRef}
        name={`${filename}_octahedron`}
        visible={true}
        rotateY={Math.PI / 2}
      >
        <octahedronGeometry args={[0.04, 1]} />
        <meshBasicMaterial wireframe transparent opacity={0.25} />
      </mesh>
      <Text
        fontSize={0.0035}
        font={"/fonts/Roboto_Condensed/static/RobotoCondensed-Regular.ttf"}
        anchorX="center"
        anchorY="top"
        position={[0, -0.045, 0]}
      >
        Modèle non disponible
      </Text>
    </group>
  );
};
export default FragmentDummy;
