import React from "react";
import { Box, Typography, Divider, Stack, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import HubIcon from "@mui/icons-material/Hub";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocation as useWocation, useRoute as useWoute } from "wouter";
import useHashLocation from "../../../hooks/useHashLocation";

const BulleFragment = (props) => {
  const [hashLoc, hashNav] = useHashLocation();

  const navigate = useNavigate();
  const handleClick = () => {
    // hashNav(`/fragments/${props.fragment}`);
    navigate(`/fragments/${props.fragment}`);

    // setLocation(`/fragments/${props.fragment}`);
  };
  const handleClickSection = () => {
    navigate(`/fragments/${props.section}`);
  };

  return (
    <Box sx={{ p: 1 }}>
      <>
        <Stack sx={{ pt: 1 }} direction="column" alignItems="start">
          <Button
            size="small"
            startIcon={<ViewInArOutlinedIcon fontSize="small" />}
            onClick={handleClick}
          >
            Voir ce fragment
          </Button>
          <Button
            size="small"
            startIcon={<HubIcon fontSize="small" />}
            onClick={handleClickSection}
          >
            Voir les fragments
          </Button>
          {/* <Button
            size="small"
            startIcon={<OpenInNewIcon fontSize="small" />}
            target="_blank"
            href={props.target}
            variant="text"
          >
            archimage
          </Button> */}
        </Stack>
        {/* <Divider /> */}
      </>

      {/* <Stack sx={{ pt: 1 }} direction="row" alignItems="end">
        <Button
          size="small"
          startIcon={<OpenInNewIcon fontSize="small" />}
          target="_blank"
          href={props.target}
          variant="text"
        >
          archimage
        </Button>
      </Stack> */}
    </Box>
  );
};

export default BulleFragment;
