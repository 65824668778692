import React, { useEffect, useState, useRef, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useRoute } from "wouter";

import useDefinitionStore from "../../../stores/useDefinitionStore";
import useUiStore from "../../../stores/useUiStore";

import { checkIfUrlExists } from "../../../outils";

import {
  useAnnotation2D,
  useQueriesAPI,
  useKeys,
  useFragmentOccurences,
  useGetOccurences,
} from "../../hooks";

import NoticeDrawer from "../notices/ui/noticeDrawer";
import GalleryDrawer from "../galerie/ui/galerieDrawer";
import GalleryModal from "../galerie/ui/galerieModal";

import CanvasFragment from "./canvasFragment";
import FragmentDummy from "./fragmentDummy";

import PseudoCanvas from "../common/3d/pseudoCanvas";

import {
  FragmentFabs,
  FragmentSettingsCard,
  FragmentTabs,
  FragmentAnnotationCard,
  FragmentAnnotations2D,
  FragmentRadioAnnotations3D,
  Entete,
  FragmentAnnotation3DCard,
} from "./ui";

import { ListOccurences } from "../common/ui";

const Fragment = (props) => {
  const [, params] = useRoute("/:section/:annotation");

  const { numero } = props;
  const navigate = useNavigate();

  const [openSettings, setOpenSettings] = useState(false);
  const [openNotice, setOpenNotice] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [fitToSphere, setFitToSphere] = useState(false);
  const [annotationsOn, setAnnotationsOn] = useState(false);
  const [hasModele, setHasModele] = useState(false);
  const [relatedTexts, setRelatedTexts] = useState();
  // const [hasAnnotations2D, setHasAnnotations2D] = useState();

  const currentCouche = useDefinitionStore((state) => state.currentCouche);
  const setCurrentCouche = useDefinitionStore(
    (state) => state.setCurrentCouche
  );

  // const paramsTexte = useUiStore((state) => state.paramsTexte);t

  const couche = {
    materialite: "matérialité",
    iconographie: "iconographie",
  };

  const {
    data: fragment,
    isSuccess,
    isLoading,
    isError,
  } = useQueriesAPI(`fragments/${numero}`);

  let isFragment;

  fragment?.gltf && fragment?.gltf_5k
    ? (isFragment = true)
    : (isFragment = false);

  const width = "800px";

  const { isSuccess: hasAnnotations2D } = useAnnotation2D(
    fragment?.numero_inventaire_3d
  );

  useKeys("ArrowUp", () => navigate("/fragments"));

  // const fragmentOccurences = useFragmentOccurences(numero);
  const fragmentOccurences = useGetOccurences({ type: "tholos", id: numero });

  /**
   *  HANDLERS
   */

  const handleSettings = (value) => (e) => {
    setOpenSettings(value);
  };

  const handleNotice = () => {
    setOpenNotice(!openNotice);
  };

  const handleFitToSphere = () => {
    setFitToSphere(!fitToSphere);
  };

  const handleTabs = (e, newValue) => {
    setCurrentCouche(newValue);
  };

  const toogleAnnotations = () => {
    setAnnotationsOn(!annotationsOn);
  };

  // const handleGalleryDrawer =() => {
  //   openGallery ? setOpenGalleryModal(true) : setOpenGallery(!openGallery);
  //   setOpenGallery(openGallery);
  //   setOpenGalleryModal(!openGalleryModal);

  // }

  /**
   * EFFECTS
   *
   */

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        navigate("/fragments");
      }, 2000);
    }
  });

  useEffect(() => {
    let filename = numero.split(",").join("-");
    let isModele = checkIfUrlExists(`/modeles/${filename}/${filename}.glb`);
    setHasModele(isModele);
  }, []);

  /**
   *
   *
   *
   */

  return isSuccess && fragment?.numero_inventaire_3d && hasModele ? (
    <>
      <div
        id="reglages-fragments"
        sx={{ visibility: openSettings ? "visible" : "hidden" }}
      >
        <FragmentSettingsCard
          open={openSettings}
          handleSettings={handleSettings}
        />
      </div>

      <FragmentAnnotationCard openNotice={openNotice} width={width} />
      <FragmentAnnotation3DCard
        openNotice={openNotice}
        openGallery={openGallery}
        width={width}
        fragment={fragment.numero_inventaire_3d}
      />

      <NoticeDrawer
        open={openNotice}
        setOpenNotice={setOpenNotice}
        width={width}
        fragment={fragment?.numero_inventaire_3d}
      />

      <GalleryDrawer
        width={width}
        open={openGallery}
        setOpenGallery={setOpenGallery}
        fragment={fragment?.numero_inventaire_3d}
      />

      <GalleryModal open={openGalleryModal} setOpen={setOpenGalleryModal} />

      {/* <CanvasCouche /> */}

      <Entete
        numero={numero}
        sousEntete={couche[currentCouche]}
        isSousEntete={!openNotice}
      />
      {fragmentOccurences && <ListOccurences occurences={fragmentOccurences} />}

      <CanvasFragment
        name={fragment.numero_inventaire_3d}
        numero={numero}
        rotationFragment={isFragment ? fragment.rotation_xyz : null}
        width={openNotice || openGallery ? width : "0px"}
        fitToSphere={fitToSphere}
        handleFitToSphere={handleFitToSphere}
        setFitToSphere={setFitToSphere}
        setOpenNotice={setOpenNotice}
        openNotice={openNotice}
        couche={currentCouche}
        annotationsOn={annotationsOn}
        isFragment={isFragment}
        isModele={hasModele}
      />

      <footer>
        <div className="footer">
          <span className="alignLeft">
            <FragmentFabs
              handleSettings={handleSettings}
              handleFitToSphere={handleFitToSphere}
              toogleAnnotations={toogleAnnotations}
              setOpenNotice={setOpenNotice}
              openNotice={openNotice}
              annotationsOn={annotationsOn}
              setOpenGallery={setOpenGallery}
              openGallery={openGallery}
              setOpenGalleryModal={setOpenGalleryModal}
              openGalleryModal={openGalleryModal}
              hasAnnotations2D={hasAnnotations2D}
            />
          </span>

          <span className="alignRight"></span>
        </div>
      </footer>

      {!openNotice && !openGallery ? (
        <>
          <FragmentRadioAnnotations3D
            fragment={fragment.numero_inventaire_3d}
          />
          <FragmentTabs handleChange={handleTabs} value={currentCouche} />
        </>
      ) : null}
    </>
  ) : isLoading ? (
    <Entete numero={numero} sousEntete="chargement ..." isSousEntete={true} />
  ) : (
    <>
      <Entete
        numero={numero}
        sousEntete="ce numéro n'est pas attribué"
        isSousEntete={true}
      />
      <PseudoCanvas width={openNotice || openGallery ? width : "0px"}>
        <FragmentDummy />
      </PseudoCanvas>
    </>
  );
};

export default Fragment;
