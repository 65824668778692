import React from "react";
import { Typography } from "@mui/material";

const Entete = (props) => {
  const { numero, sousEntete, isSousEntete } = props;
  return (
    <>
      <div className="entete">
        <Typography variant="h3" component="div" gutterBottom>
          n°{numero}
        </Typography>
      </div>
      {/* {isSousEntete ? <div className="sous-entete">{sousEntete}</div> : null} */}
    </>
  );
};

export default Entete;
