import React from "react";
import { Tooltip, Typography, Fab, Box } from "@mui/material";

const CustomFab = ({ title, style, onClick, children, disabled }) => {
  return (
    <Tooltip
      title={<Typography>{title}</Typography>}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.black",
            "& .MuiTooltip-arrow": {
              color: "common.black",
            },
          },
        },
      }}
    >
      <Box display="inline-flex">
        <Fab
          size="small"
          onClick={onClick}
          sx={style}
          disabled={false || disabled}
        >
          {children}
        </Fab>
      </Box>
    </Tooltip>
  );
};
export default CustomFab;
