import React, { useState } from "react";
import { Canvas, useThree } from "@react-three/fiber";

import Lights from "../common/3d/lights";
import Fabs from "../common/ui/fabs";
import SettingsCard from "../common/ui/settingsCard";

import Rig from "./rig";

const CanvasSelection = () => {
  const [openSettings, setOpenSettings] = useState(false);
  const handleSettings = (value) => (e) => {
    setOpenSettings(value);
  };
  return (
    <>
      <div
        id="reglages-fragments"
        sx={{ visibility: openSettings ? "visible" : "hidden" }}
      >
        <SettingsCard
          open={openSettings}
          handleSettings={handleSettings}
          title="Réglages"
          subheader="Fragments"
        />
      </div>
      <div className="webgl">
        <Canvas
          id="scene3D"
          flat
          shadows
          dpr={[1, 2]}
          camera={{
            fov: 5,
            near: 0.01,
            far: 350,
            position: [0, 0, 40],
          }}
        >
          <Lights />
          <Rig />
        </Canvas>
      </div>
      <footer>
        <div className="footer">
          <span className="alignLeft">
            <Fabs handleSettings={handleSettings} />
          </span>
        </div>
      </footer>
    </>
  );
};
export default CanvasSelection;
