import React, { useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCursor, Text } from "@react-three/drei";

import useUiStore from "../../../stores/useUiStore";

import ModelPreview from "../common/3d/modelPreview";

const Frame = forwardRef((props, ref) => {
  const { filename, cellSize, rotationFragment, handleClick } = props;
  const [hovered, setHovered] = useState(false);

  const navigate = useNavigate();

  const paramsFragments = useUiStore((state) => state.paramsFragments);

  let numero = filename.replace("-", ",");

  useCursor(hovered);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleDoubleClick = (e) => {
    e.stopPropagation();
    navigate(`/fragments/${numero}`);
  };
  return (
    <group
      ref={ref}
      {...props}
      position={[props.index * 0.6, 0, 0]}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onPointerEnter={handleMouseEnter}
      onPointerLeave={handleMouseLeave}
    >
      <ModelPreview
        filename={filename}
        hovered={hovered}
        cellSize={cellSize}
        rotationFragment={rotationFragment}
      />

      <Text
        font={"/fonts/Roboto_Condensed/static/RobotoCondensed-Regular.ttf"}
        fontSize={0.075}
        anchorY="bottom"
        position={[0, -cellSize / 1.75, 0]}
        color={hovered ? "orange" : "grey"}
        visible={paramsFragments?.numero?.value ? true : false}
      >
        {numero}
      </Text>
    </group>
  );
});

export default Frame;
