import React from "react";
import {
  Box,
  Typography,
  Divider,
  Stack,
  Button,
  Skeleton,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const BulleArchimageIIIF = (props) => {
  // console.log(props);

  // image_preview type 2 = 400px

  let urlIIIF = `https://archimage.efa.gr/?r=visionneuse_iiif&id=${props.Id}`;

  return (
    <Box sx={{ p: 1 }}>
      {props[`Référence`] && (
        <>
          <Stack>
            <Typography variant="overline" gutterBottom>
              <span>
                {props[`Type de document`]} : {props[`Référence`]}
              </span>
            </Typography>
          </Stack>

          {/* <Divider /> */}
        </>
      )}

      {props[`Légende`] && (
        <>
          <Stack sx={{ pt: 1 }}>
            <img
              style={{
                marginBottom: "10px",
                objectFit: "contain",
              }}
              src={`https://archimage.efa.gr/action.php?kroute=image_preview_public&id=${props.Id}&type=2&ext=.jpg`}
              title={props[`Légende`]}
            />

            <Typography variant="body2" paragraph align="justify">
              <span>{props[`Auteur du document`]} (auteur du document) </span>
            </Typography>
          </Stack>
          <Divider />
        </>
      )}
      <Stack sx={{ pt: 1 }} direction="row" alignItems="end">
        <Button
          size="small"
          startIcon={<OpenInNewIcon fontSize="small" />}
          target="_blank"
          href={props.target}
          title="Fiche publique sur archimage"
          // variant="text"
        >
          archimage
        </Button>
        <Button
          size="small"
          startIcon={<OpenInNewIcon fontSize="small" />}
          target="_blank"
          href={urlIIIF}
          title="Visionneuse IIIF"
          // variant="text"
        >
          visionneuse IIIF
        </Button>
      </Stack>
    </Box>
  );
};

export default BulleArchimageIIIF;
