import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { useFastXmlParser } from "../../../hooks";
import * as jp from "jsonpath";
import BulleNote from "./bulleNote";

const TooltipNote = (props) => {
  const { id, note, text } = props;
  const [noteData, setNoteData] = useState();

  const noteOptions = {
    ignoreAttributes: false,
    attributeNamePrefix: "",
    trim: true,
    stopNodes: ["*.p"],
    processEntities: true,
    ignoreDeclaration: true,
    ignorePiTags: true,
    removeNSPrefix: true,
    isArray: (name, jpath, isLeafNode, isAttribute) => {
      if (["p"].indexOf(name) !== -1) return true;
    },
  };

  const parsedText = useFastXmlParser(text, noteOptions);

  useEffect(() => {
    if (!parsedText) return;
    let [res] = jp.query(parsedText, `$..note[?(@.id=="${id}")]`);
    setNoteData(res);
  }, [parsedText]);

  return (
    <Tooltip
      id={id}
      title={text && noteData ? <BulleNote text={noteData.p} id={id} /> : null}
      arrow
      leaveDelay={250}
    >
      <sup className="note">&#8239;{note}&#8239;</sup>
    </Tooltip>
  );
};

export default TooltipNote;
