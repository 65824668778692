import React from "react";
import { Box, Typography, Divider, Stack, Button } from "@mui/material";
import Paragraphe from "../paragraphe";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const BulleNote = (props) => {
  const { text, id } = props;

  return (
    <Box sx={{ pt: 1 }}>
      {text && (
        <>
          {/* {text &&
            text.map((t, i) => (
              <Typography key={i} variant="body2" paragraph align="justify">
                {t}
              </Typography>
            ))} */}

          {text.map((t, i) => (
            <Paragraphe text={t} id={id} key={i} />
          ))}
        </>
      )}
    </Box>
  );
};

export default BulleNote;
