import React, { useRef, useState, useMemo } from "react";
import * as THREE from "three";

import { useFrame } from "@react-three/fiber";
import { useGLTF, Icosahedron } from "@react-three/drei";
import { useRoute } from "wouter";
import { useNavigate } from "react-router-dom";
import Label from "../ui/label";

const Fragment = (props) => {
  const {
    hovered,
    name,
    position,
    rotation,
    collection,
    handleLocalClick,
    index,
  } = props;
  const pathToModel = `/modeles/${name}/5K/${name}.glb`;
  const pathToDraco = `/draco/`;

  const model = useGLTF(pathToModel, pathToDraco);

  const group = useRef();
  const matRef = useRef();
  const meshRef = useRef();
  const icoRef = useRef();

  const cameraControlsRef = useRef();

  const [localHovered, setLocalHovered] = useState();

  const navigate = useNavigate();
  const [match, params] = useRoute(`/?/fragments`);

  const { nodes, materials } = model;
  const { Scene, ...mesh } = nodes;
  const [meshName] = Object.keys(mesh);

  const material = Object.values(materials).find((mat) => mat.isMaterial);
  material.name = `${name}_5K_mat`;

  let geometryName = nodes[name] ? name : `${name}_5K`;

  //   const rotationRAD = rotation.map((r) => r * DEG2RAD);

  const grey = new THREE.Color("grey");
  const goldenrod = new THREE.Color("goldenrod");
  const marble = new THREE.Color(0xa29a99);
  const white = new THREE.Color("white");
  const emissive = new THREE.Color(0xa29a99);

  const onPointerOver = (e) => {
    // if (match) {
    //   let myAcrotere = fragmentsAcroteres.find((f) => f.decor_ID === name);

    //   myAcrotere && setData(myAcrotere);
    // }
    setLocalHovered(true);
    // setTimeout(() => {
    //   setLocalHovered(true);
    // }, 500);
  };
  const onPointerOut = (e) => {
    setLocalHovered(false);
    // setTimeout(() => {
    //   setLocalHovered(false);
    // }, 500);
  };

  const handleDoubleClick = (e) => {
    //   if (!data) {
    //     return;
    //   }

    navigate(`/fragments/${name.replace("-", ",")}`, {
      state: { from: "/tholos/fragments" },
    });
  };

  let delay = 0.3;
  let coef = 0.001;

  useFrame((state, delta) => {
    meshRef.current.rotation.y += delta * 0.1;

    !match &&
      !localHovered &&
      ((group.current.position.y +=
        Math.sin(state.clock.getElapsedTime()) * coef),
      (group.current.position.x +=
        Math.cos(state.clock.getElapsedTime()) * coef * 0.5));
    matRef.current.color.lerp(
      hovered && !match
        ? goldenrod
        : localHovered && match
        ? goldenrod
        : marble,
      delay
    );
  });

  useGLTF.preload(pathToModel, pathToDraco);

  return (
    <group
      ref={group}
      dispose={null}
      name={name}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
      onDoubleClick={handleDoubleClick}
      position={position}
      rotation={rotation}
      userData={{ collection: collection }}
      collection={collection}
      onClick={handleLocalClick}
    >
      <mesh
        ref={meshRef}
        name={`${name}_mesh`}
        castShadow
        receiveShadow
        geometry={nodes[meshName].geometry}
      >
        {/* <meshLambertMaterial
          //   color={marble}
          ref={matRef}
          map={material.map}
          //   emissive={emissive}
          //   emissiveIntensity={0.5}
        /> */}
        <meshStandardMaterial
          ref={matRef}
          //  map={material.map}
        />

        {/* <meshBasicMaterial color={marble} ref={matRef} map={material.map} /> */}
      </mesh>
      <Icosahedron
        ref={icoRef}
        name={`${name}_gizmo`}
        args={[0.4, 0]}
        visible={false}
      >
        <meshStandardMaterial transparent opacity={0.5} />
      </Icosahedron>

      {localHovered && match ? (
        <Label name={name} />
      ) : localHovered && !match ? (
        <Label title="Fragments" />
      ) : null}
    </group>
  );
};

export default Fragment;
