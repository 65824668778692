import React, { useState, useEffect } from "react";
import { Box, Fab, Badge } from "@mui/material";
import { useRoute, useLocation, Route, useParams } from "wouter";

import CustomFab from "../../../ui/fabs/customFab";

import CloseIcon from "@mui/icons-material/Close";

const FabsRight = (props) => {
  const [location, setLocation] = useLocation();
  const [route, params] = useRoute(":id");

  const backgroundColor = "#FFFFFF66";

  const handleClose = () => {
    setLocation("/");
  };

  const fabStyle = { backgroundColor: backgroundColor };

  // console.log("fabsRight", route, params, location);

  return (
    <Box
      sx={{
        opacity: params?.id ? 1 : 0,

        pointerEvents: "none",
        transition: "opacity 0.5s ease-in-out",
        "& > :not(style)": { m: 1, pointerEvents: "auto" },
      }}
      id="fabsRight"
    >
      <CustomFab title="Sortir du focus" onClick={handleClose} style={fabStyle}>
        <CloseIcon />
      </CustomFab>
    </Box>
  );
};

export default FabsRight;
